import {post} from "@/services/ApiReq";

export default {
  actions: {
   SendMailBack(getters,form){
    return new Promise((resolve,reject)=>{
      post(`mail`, form).then((res)=> {
        resolve(res);
        }).catch((e) => {
          reject(e)
        });
      })

   } 
  }
}