import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home/Home.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/calendario',
        name: 'Calendario',
        component: () => import(/* webpackChunkName: "calentario" */ '@/views/Calendario/Calendario.vue')
    },
    {
        path: '/exposiciones/:url',
        redirect: (to) => {
            return {path: '/resultados/' + to.params.url}
        }
    },
    {
        path: '/examenes/:url',
        redirect: (to) => {
            return {path: '/resultados/' + to.params.url}
        }
    },
    {
        path: '/resultados/:url',
        //name: 'estructura',
        component: () => import( '@/views/DataSistema/DataSistemaLayout.vue')
    },
    {
        path: '/buscar-un-cachorro/:formato/:tipo/:url',
        name: 'nacimientos-servicios',
        redirect: (to) => {
            return {path: '/resultados/' + to.params.url}
        }
        //component: () => import(/* webpackChunkName: "nacimientos-servicios" */ '@/views/DataSistema/DataSistemaLayout.vue')
    },
    {
        path: '/servicios/:url',
        redirect: (to) => {
            return {path: '/buscar-un-cachorro/card/servicios/' + to.params.url}
        }
    },
    {
        path: '/siegerschau/:url',
        redirect: (to) => {
            return {path: '/resultados/card/siegerschau/' + to.params.url}
        }
    },
    {
        path: '/torneo/:url',
        name: 'torneo',
        component: () => import(/* webpackChunkName: "torneo" */ '@/views/Torneo/Torneo.vue')
    },
    {
        path: '/:tipo/:url',
        name: 'Contenido',
        component: () => import(/* webpackChunkName: "institucion" */ '@/views/Contenido/ContenidoLayout.vue')
    },
    {
        path: '/revistas/',
        name: 'Revistas',
        component: () => import("@/views/Revista/Revistas.vue")

    },
    {
        path: '/revista/:entrega',
        name: 'Revista',
        component: () => import("@/views/Revista/Revista.vue")

    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
