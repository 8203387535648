import axios from "axios";

export const backend_url = "https://back.clubpoa.com.ar:3001"
export const ad_url = "https://ads.clubpoa.com.ar"
export const ad_api = "https://ads.clubpoa.com.ar/api"

const axiosService = axios.create({
  baseURL: backend_url,
  //baseURL: process.env.VUE_APP_API || "https://backend.clubpoa.com.ar:3001/",
});

export const get = (path, params) => {
  return axiosService.get(path, params );
};

export const post = (path, body) => {
  return axiosService.post(path, body);
};

export const put = (path, body) => {
  return axiosService.put(path, body);
};

export const patch = (path, body) => {
  return axiosService.patch(path, body);
}

export const del = (path) => {
  return axiosService.delete(path);
};
