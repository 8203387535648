import {get} from "@/services/ApiReq";
import { findParent } from "@/utils/findParent";

export default {
  state: {
    menu: {},
  },
  mutations: {
    SET_MENU(state, value) {
      state.menu = value.reduce((obj, curr) => {
        if (!curr.id_padre){
          // es principal
          obj[curr.id] = {...curr, submenu: {}};
          return obj;
        }else{
          // es submenu
          return findParent(obj, curr)
        }
      }, {});
    },
  },
  actions: {
    getMenu({ commit }) {
      return new Promise((resolve) => {
        get(`categorias/menu`)
          .then((res) => {
            commit("SET_MENU", res.data);
            resolve();
          })
          .catch((err) => {console.log(err)});
      });
    },
  },
  getters: {
    menu: (state) => state.menu,
  },
};
