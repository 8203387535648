<template>
  <div class="content-spinner">
    <div class="spinner"> </div>
  </div>
</template>

<script>
export default {
 name: 'Spinner'
}
</script>

<style scoped>
.content-spinner{
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
}
.spinner{
  border: 4px solid rgba(0,0,0,.1);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border-left-color: #65c178;

  animation: spin 1s ease infinite;
}

@keyframes spin {
  0%{
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>