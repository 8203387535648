const axios = require("axios");

const backendUrl = "https://back.clubpoa.com.ar:3001";
// ``
/* eslint-disable */
export default {
  state: {
    results: {},
    resultados: {}
  },
  mutations: {
    SET_RESULTS(state, value) {
      state.results = value;
    },
    SET_ULTIMOS_RESULTADOS(state, value) {
      state.resultados = value;
    }
  },
  actions: {
    getResults({commit},) {
      return new Promise((resolve) => {
        axios.get("http://www.localhost:3000/helper?url=http://66.97.43.33/rest/api/v1/resultados_estructura?filter=%7B%22calendario_id%22:1089,%22sexo%22:%22M%22%7D")
          .then((res) => {
            commit("SET_RESULTS", res.data);
            resolve();
          })
          .catch(function (error) {
            console.error(error);
          });
      })
    },
    async findUltimosResultados({commit}) {
      let respuesta = null;
      console.log(backendUrl)
      await axios.post(`${backendUrl}/sistema/resultados/ultimos`).then((response) => {
        commit("SET_ULTIMOS_RESULTADOS", response.data.data);
      });
    }
  },
  getters: {
    results: (state) => state.results,
    ultimos_resultados(state) {
      return state.resultados;
    }
  },
}


