<script>
    import CardFeature from "./CardFeature.vue";
    
    export default {
  mounted() {
    this.$store.dispatch("getFeatures");
  },
  components: {
    CardFeature,
},
  computed: {
    features() {
      return this.$store.getters.features;
    },
  },
};

</script>

<template>
    <section class="feature-cards">
      <div class="container pt-0">
        <div class="section-content">
          <div class="row">
            <CardFeature
              v-for="(feature,index) in features"
              :key="index"
              :item="{
                ...feature,
                icon: feature.contenido_bonus.find(
                  (item) => item.label == 'ICON'
                ).value,
                index: index,
                urlButton: feature.contenido_bonus.find(
                  (item) => item.label == 'URL EXTERNA'
                )?.value || feature.url,
              }"
            />
          </div>
        </div>
      </div>
    </section>
</template>

<style>
/* .feature-cards{
  min-height: 500px;
} */
</style>
