<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
/* eslint-disable */
import "vue3-carousel/dist/carousel.css";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import CardResultados from "./CardResultados.vue";
import CarouselResultados from "../../../../components/CarouselResultados.vue";

export default {
  async mounted() {
    await this.$store.dispatch("findUltimosResultados");
    window.addEventListener("resize", () => {
      this.screen_width = window.innerWidth;
    });
    /*
    await this.$store.dispatch("getImgTopbar");
    this.sponsors = this.$store.getters.imgTopbar.map((x) => {
      return {
        ...x,
        urlButton:
          x.contenido_bonus.find((b) => b.label === "URL EXTERNA")?.value || "",
      };
    });
    */
  },
  data: () => {
    return {
      screen_width: window.innerWidth,
      sponsors: [],
    };
  },
  components: {
    CardResultados,
    Carousel,
    Navigation,
    Slide,
    CarouselResultados,
  },
  methods: {
    toSend(item) {
      this.$router.push(item);
    },
    parseDate(date) {
      if (date == null) return "indefinido";
      let _date = new Date(date);
      let day = _date.getDate() == 31 ? _date.getDate() : _date.getDate() + 1;
      let month =
        _date.getMonth() == 12 ? _date.getMonth() : _date.getMonth() + 1;

      return `${day}/${month}`;
    },
  },
  computed: {
    resultados() {
      return this.$store.getters.ultimos_resultados;
    },
    itemsMostrados() {
      if (this.screen_width > 768) {
        return 3;
      } else if (this.screen_width > 576) {
        return 2;
      } else {
        return 1;
      }
    },
  },
};
</script>

<template>
  <section
    class="divider parallax layer-overlay overlay-dark-8"
    style="background-image: url('/images/Ovejero3.jpg')"
  >
    <div class="publicidad">
      <CarouselResultados></CarouselResultados>
    </div>

    <div class="container pb-17">
      <div class="col-md-12">
        <h2 class="text-white mt-0 line-height-1 resultados-title">
          Últimos <span class="text-theme-colored"> Primeros Puestos</span>
        </h2>
      </div>

      <template v-if="resultados">
        <template v-if="resultados.length">
          <template v-for="resultado in resultados" :key="resultado">
            <div class="section-title text-center">
              <div class="row">
                <div class="col-md-12">
                  <h2 class="text-white mt-0 line-height-1">
                    <span
                      >Resultados - {{ resultado[0].torneo_nombre }}
                      {{ parseDate(resultado[0].fecha_desde) }} al
                      {{ parseDate(resultado[0].fecha_hasta) }}</span
                    >
                  </h2>
                  <h3 class="text-white mt-0 line-height-1">
                    <span class="text-theme-colored">{{
                      resultado[0].fecha_nombre
                    }}</span>
                  </h3>
                  <div class="mt-10 btn-table-go">
                    <a
                      :href="`/resultados/estructura?torneo=${resultado[0].torneo_cod}&calendario=${resultado[0].calendario_id}`"
                      class="btn btn-theme-colored btn-sm btn-resultado"
                      >Ver resultados</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- :autoplay="2000" :transition="500" -->

            <template v-if="resultado">
              <carousel
                :items-to-show="itemsMostrados"
                :wrapAround="true"
                :transition="3000"
                :autoplay="5000"
              >
                <CardResultados
                  v-for="card in resultado"
                  :key="card.id"
                  :item="card"
                />

                <template #addons>
                  <navigation />
                </template>
              </carousel>
            </template>
          </template>
        </template>
      </template>
    </div>
  </section>
</template>

<style>
.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  position: absolute;
  background-color: #65c178;
}

.carousel__prev {
  left: -20px;
}

.carousel__next {
  right: -20px;
}

@media (max-width: 768px) {
  .carousel__prev {
    left: 0px;
  }

  .carousel__next {
    right: 0px;
  }
}

.btn-table-go {
  display: flex;
  justify-content: flex-end;
}
</style>


<style scoped>
.publicidad {
  width: 100%;
  margin: auto;
  margin-top: 1em;
}

@media screen and (min-width: 768px) {
  .publicidad {
    width: 768px;
  }
}

@media screen and (max-width: 768px) {
  .resultados-title {
    text-align: center;
  }
  .btn-resultado {
    margin: auto;
    margin-top: 1em;
  }
}
</style>