/* eslint-disable */
import {get, post, ad_api} from "@/services/ApiReq";
import axios from "axios";

const publicidad_api = "http://localhost:8000/api";

export default {
    state: {
        contenidos: [],
        contenido: {},
        comite: [],
        jueces: [],
        exposicion: {
            resultados: null
        },
        exposicion_calendarios: null,
        exposicion_torneos: null,
        exposicion_years: null,
        examen: {
            resultados: null,
            years: null
        },
        nacimientos: {
            resultados: null,
            years: null
        },
        siegerschau: {
            years: null,
            calendarios: null,
            resultados: null
        },
        radiologos: [],
        filters: "not-ready",
        isFetching: false,
        info_can: null,
        // active_request: [],
        active_request: 0,
        url_query: {
            torneo: '',
            calendario: ''
        },
        publicies: [],
    },
    mutations: {
        SET_CONTENIDOS(state, payload) {
            state.contenidos = payload;
        },
        SET_COMITE(state, payload) {
            state.comite = payload;
        },
        SET_CONTENIDO(state, value) {
            state.contenido = value;
        },
        SET_JUECES(state, value) {
            state.jueces = value;
        },
        SET_RADIOLOGOS(state, value) {
            state.radiologos = value;
        },
        SET_EXPOSICION(state, value) {
            /*state.exposicion.torneos = value.torneos ? value.torneos : state.exposicion.torneos;
            state.exposicion.years = value.years ? value.years : state.exposicion.years;
            state.exposicion.calendarios = value.calendarios ? value.calendarios : state.exposicion.calendarios;*/
            state.exposicion.resultados = value.data;
            state.exposicion.resultados.total = value.total;



            // state.isFetching = false;

            //console.log(value);
        },
        SET_FILTERS(state, value) {
            state.exposicion_torneos = value.torneos;
            state.exposicion_years = value.years;
            state.exposicion_calendarios = value.calendarios;
            /*
              state.exposicion.torneos = value.torneos ? value.torneos : state.exposicion.torneos;
              state.exposicion.years = value.years ? value.years : state.exposicion.years;
              state.exposicion.calendarios = value.calendarios ? value.calendarios : state.exposicion.calendarios;*/
        },
        SET_SIEGERSCHAU(state, value) {
            state.siegerschau.years = value.years ? value.years : state.siegerschau.years;
            state.siegerschau.calendarios = value.calendarios ? value.calendarios : state.siegerschau.calendarios;
            state.siegerschau.resultados = value.resultados ? value.resultados : state.siegerschau.resultados;
        },
        SET_EXAMEN(state, value) {
            //console.log(value);
            if (value.years || value.resultados) {
                state.examen.years = value.years?.data ? value.years.data.sort((a, b) => parseInt(b.fecha) - parseInt(a.fecha)).map(c => c.fecha) : state.examen.years;
                state.examen.resultados = value.resultados.data ? value.resultados.data : state.examen.resultados;
            } else {
                state.examen.resultados = value.data ? value.data : state.examen.resultados;
            }
            state.isFetching = false;
        },
        SET_NACIMIENTOS(state, value) {
            state.nacimientos.resultados = value.data;
            if (value.years) {
                state.nacimientos.years = value.years?.data ? value.years.data.sort((a, b) => parseInt(b.fecha) - parseInt(a.fecha)).map(c => c.fecha) : state.nacimientos.years;
            }
        },
        SET_FETCHING(state, value) {
            // console.info('llegue a set fetching con value', value)

            state.isFetching = null;
            state.isFetching = value;
        },
        SET_CAN_INFO(state, value) {
            state.info_can = value;
        },
        CLEAR_EXAMEN(state, payload) {
            const {years, resultados} = payload;
            if (years) state.examen.years = null
            if (resultados) state.examen.resultados = null
        },
        CLEAR_NACIMIENTOS(state, payload) {
            const {years, resultados} = payload;
            if (years) state.nacimientos.years = null
            if (resultados) state.nacimientos.resultados = null
        },
        CLEAR_STATE(state) {
            state.contenido = {};
            state.comite = [];
            state.exposicion = {};
        },
        CLEAR_EXPOSICION(state, payload) {
            const {torneos, years, calendarios, resultados} = payload;
            if (torneos) state.exposicion.torneos = null;
            if (years) state.exposicion.years = null;
            if (calendarios) state.exposicion.calendarios = null;
            if (resultados) state.exposicion.resultados = null;
        },
        CLEAR_SIEGERSCHAU(state, payload) {
            const {years, calendarios, resultados} = payload;
            if (years) state.siegerschau.years = null;
            if (resultados) state.siegerschau.resultados = null;
            if (calendarios) state.siegerschau.calendarios = null;
        },
        SET_REQUEST(state, num) {
            state.active_request += num;
        },
        SET_URL_QUERY(state, params) {
            state.url_query = {
                torneo: params.torneo,
                calendario: params.calendario
            };
        },
    },
    actions: {
        getContenidos({commit}, tipo) {
            return new Promise((resolve, reject) => {
                get(`contenidos/${tipo}`)
                    .then((response) => {
                        commit("SET_CONTENIDOS", response.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        getComite({commit}) {
            return new Promise((resolve, reject) => {
                get(`comite-ejecutivo/get-comite`)
                    .then((response) => {
                        commit("SET_COMITE", response.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        getJueces({commit}) {
            return new Promise((resolve, reject) => {
                get(`jueces/get-jueces`)
                    .then((response) => {
                        commit("SET_JUECES", response.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        getContenido({commit}, {url, tipo}) {
            return new Promise((resolve) => {
                get(`contenidos/${tipo}/?url=${url}`)
                    .then((res) => {
                        commit("SET_CONTENIDO", res.data[0]);
                        resolve();
                    })
                    .catch((err) => {
                        console.log(err)
                    });
            });

        },
        getExposicion({commit}, {url, params, paramsb}) {
            commit("SET_FETCHING", true);
            commit('SET_REQUEST', 1);

            
            return new Promise((resolve, reject) => {
                get(`sistema/exposicion/${url}`, (paramsb?.calendario) ? {paramsb} : {params})
                    .then((res) => {
                        commit("SET_EXPOSICION", res.data);
                        commit('SET_REQUEST', -1);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                        console.log(err)
                    });
            });
        },
        getExamen({commit}, {url, params}) {
            commit("SET_FETCHING", true)
            commit('SET_REQUEST', 1);

            return new Promise((resolve) => {
                get(`sistema/examenes/${url}`, {params})
                    .then((res) => {
                        commit("SET_EXAMEN", res.data);
                        // commit("SET_FETCHING", false)
                        commit('SET_REQUEST', -1);
                        resolve();
                    })
                    .catch((err) => {
                        console.log(err)
                    });
            });
        },
        getNacimientos({commit}, {url, params}) {
            commit("SET_FETCHING", true)
            return new Promise((resolve) => {
                get(`sistema/${url}`, {params})
                    .then((res) => {
                        commit("SET_NACIMIENTOS", res.data);
                        commit("SET_FETCHING", false)

                        resolve();
                    })
                    .catch((err) => {
                        console.log(err)
                    });
            });
        },
        getSiegerschau({commit}, {url, params}) {
            return new Promise((resolve) => {
                get(`sistema/siegerschau/${url}`, {params})
                    .then((res) => {
                        commit("SET_SIEGERSCHAU", res.data);
                        commit("SET_FETCHING", false)

                        resolve();
                    })
                    .catch((err) => {
                        console.log(err)
                    });
            });
        },
        getRadiologos({commit}) {
            return new Promise((resolve, reject) => {
                get(`sistema/radiologos`)
                    .then((response) => {
                        commit("SET_RADIOLOGOS", response.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        getFilters({commit}, {params, paramsb}) {
            return new Promise((resolve, reject) => {
                commit('SET_REQUEST', 1);
                get(`sistema/filters/`, (paramsb?.calendario) ? {paramsb} : {params})
                    .then((res) => {
                        commit("SET_FILTERS", res.data);
                        commit('SET_REQUEST', -1);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                        console.log(err)
                    });
            });
        },
        setFetching({commit}, state) {
            commit("SET_FETCHING", state);
        },
        getInfoCan({commit}, params) {
            return new Promise((resolve, reject) => {
                post(`sistema/can/info`, {params})
                    .then((res) => {
                        commit("SET_CAN_INFO", res.data.rows[0]);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                        console.log(err)
                    });
            });
        },
        setUrlQuery({commit}, params) {
            commit('SET_URL_QUERY', params);
        },
        getPublicies({state}, value) {
            return new Promise((resolve) => {
                axios.post(`${ad_api}/publicies/get`, value).then((response) => {
                    state.publicies = response.data.publicies;
                    resolve();
                });
            })
        },
        addClick({state}, value) {
            return new Promise((resolve) => {
                axios.post(`${ad_api}/publicies/click/add`, value).then((response) => {
                    resolve();
                });
            })
        }
    },

    getters: {
        contenidos: (state) => state.contenidos,
        contenido: (state) => state.contenido,
        comite: (state) => state.comite,
        jueces: (state) => state.jueces,
        exposicion: (state) => state.exposicion,
        examen: (state) => state.examen,
        siegerschau: (state) => state.siegerschau,
        radiologos: (state) => state.radiologos,
        nacimientos: (state) => state.nacimientos,
        filters: (state) => state.filters,
        exposicion_calendarios: (state) => state.exposicion_calendarios,
        exposicion_torneos: (state) => state.exposicion_torneos,
        exposicion_years: (state) => state.exposicion_years,
        exposicion_resultados: (state) => state.exposicion.resultados,
        checkFilters: (state) => {
            return state.exposicion.torneos || state.exposicion.years || state.exposicion.calendarios
        },
        isFetching: (state) => state.isFetching,
        info_can: (state) => state.info_can,
        active_request: (state) => state.active_request,
        url_query: (state) => state.url_query,
        publicies: (state) => state.publicies,
    },
};