<template>
  <div class="Home">
    <div class="main-content">
      <!-- Section: home -->

      <FirstCarousel/>


      <!-- Cards -->
      <Cards/>

      <!-- Que es Poa-->
      <ABlog/>
      <NoticiasYFrecuentes/>

      <!--    <ABlog/>-->
      <!-- Nuestros Servicios -->
      <VideosHome/>

      <!-- Ultimos Resultados -->
      <UltimosResultados/>

      <!-- Noticias-->

      <!--    <NoticiasYFrecuentes/>-->

      <!-- Comite Ejecutivo-->

      <!-- <ComiteEjecutivo/> -->

      <!-- Galeria -->
      <!-- <section id="gallary2" class="bg-light">
        <div class="container pb-70">
          <div class="section-title text-center">
            <div class="row">
              <div class="col-md-12">
                <h2 class="mt-0 line-height-1">
                  <span class="text-theme-colored"> Galeria</span>
                </h2>
              </div>
            </div>
          </div>
          <div class="section-content">
            <div class="row">
              <div id="grid3" class="gallery-isotope grid-4 gutter clearfix">
                <div id="imagenesGaleria" class="col-md-12">

                  <GaleriaHome
                   />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> -->

      <!-- Contacto -->
      <Contacto/>
    </div>
    <HomeModal
        v-if="showModal"
        @close="closeModal"
        :item="alertHomeModal"
    />
  </div>
</template>

<script>
import HomeModal from "./HomeModal.vue"
import FirstCarousel from "./components/FirstCarousel.vue";
// import GaleriaHome from "../Home/components/GaleriaHome.vue";
import Cards from "../Home/components/Cards/Cards.vue";
import UltimosResultados from "../Home/components/Ultimos Resultados/UltimosResultados.vue";
import NoticiasYFrecuentes from "../Home/components/Noticias y Frecuentes/NoticiasYFrecuentes.vue";
// import ComiteEjecutivo from "../Home/components/Comite Ejecutivo/ComiteEjecutivo.vue";
import VideosHome from "./VideosHome.vue";
import Contacto from "../Home/components/Contacto/Contacto.vue";
import ABlog from "./components/BlogHomeCentral/ABlog.vue";

export default {
  data: () => ({
    showModal: false,
  }),
  computed: {
    alertHomeModal() {
      return this.$store.getters?.alerta_home.find(item => item.publicar === true) || {}
    }
  },
  components: {
    // GaleriaHome,
    Cards,
    UltimosResultados,
    NoticiasYFrecuentes,
    // ComiteEjecutivo,
    VideosHome,
    Contacto,
    HomeModal,
    FirstCarousel,
    ABlog,
  },
  props: {
    contenido: Object,
    images: Array,
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },

  },
  async beforeMount() {
    let modalVisto = this.$cookies.get('modalVisto')
    if (!modalVisto) {
      this.$cookies.set("modalVisto", true, 30)
      await this.$store.dispatch("getAlertaHome");
      if (this.$store.getters.alerta_home) {
        this.$store.getters.alerta_home.forEach(item => {
          if (item?.publicar === true) this.showModal = true
        })
      }
    }
  }
}

</script>


<style scoped>
/* .controls {
  position: absolute;
  bottom: 100px;
} */
</style>
