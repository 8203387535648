<script>
import CardContacto from "./CardContacto.vue";
// import IconContacto from "./IconContacto.vue";
import PreguntasFrecuentes from "../Noticias y Frecuentes/PreguntasFrecuentes.vue";
export default {
    
  async mounted() {
    await this.$store.dispatch("getPreguntasFrecuentes");
    // await this.$store.dispatch("getIconContacto");
  },
  components: {
    CardContacto,
    PreguntasFrecuentes,
    // IconContacto,
  },
  computed: {
    // IconosContacto() {
    //   return this.$store.getters.icon_contacto;
    // },
    PreguntasFrecuentes() {
      return this.$store.getters.preguntas_frecuentes;
    },
  },
};
</script>

<template>
  <section class="divider parallax bg-light ">
    <div class="container-fluid p-0">
      <div class="row " >       
        <CardContacto />
        <!-- col-md-offset-1 -->
        <div class="col-md-5 bg-deep" style="margin-top: 2em; border-radius: 0.5em;">
          <div class="p-70">
            <div class="row">
              <h3 class="mt-0 line-height-1">
                Preguntas<span class="text-theme-colored"> Frecuentes</span>
              </h3>

              <div id="accordeon-home-preguntas-frecuentes" class="panel-group accordion">
                <PreguntasFrecuentes
                  v-for="(card, index) in PreguntasFrecuentes"
                  :key="index"
                  :item="card"
                  :index="index"
                />
              </div>
              <!-- <IconContacto
                v-for="(card, index) in IconosContacto"
                :key="index"
                :item="card"
              /> -->
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style></style>
