import { createStore } from 'vuex'
import homeData from "./_home-data"
import ui from './_ui'
import contenidos from './_contenidos'
import torneos from './_torneos'
import calendario from './_calendario'
import mail from './_mail'
import resultados from './_resultados'
import estructura from './_estructura'
import adiestramiento from './_adiestramiento'
import files from './files'
import examenes from './_examenes'
import responsive from "@/store/responsive";
import revista from "@/store/revista";

export default createStore({
    modules: {
        homeData,
        ui,
        contenidos,
        torneos,
        calendario,
        mail,
        resultados,
        files,
        estructura,
        adiestramiento,
        responsive,
        examenes,
        revista
    }
})
