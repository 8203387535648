import { get } from "@/services/ApiReq";

export default {
  state: {
    calendario: [],
  },
  mutations: {
    SET_CALENDARIO(state, value) {
      state.calendario = value;
    },
  },
  actions: {
    getCalendario({ commit }) {
      return new Promise((resolve) => {
        get(`calendario`)
          .then((res) => {
            commit("SET_CALENDARIO", res.data);
            resolve();
          })
          .catch((err) => { console.log(err) });
      });
    },
  },

  getters: {
    calendario: (state) => state.calendario,
  },
};
