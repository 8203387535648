<script>

export default {
  props:{
    item:{
      type: Object,
      default: null,
    }
  },
  computed:{
    classUnica(){
      return `flotan-cards-${this.item.index}`
    }

  },
}
</script>

<template>
    <article class="col-md-4">
      <div
        class="icon-box bg-lighter text-center p-30 mt-sm-0 border-1px"
        :class="classUnica"
      >
          <a
            class="icon icon-lg bg-theme-colored icon-circled icon-border-effect effect-circled text-white"
          >
            <i :class="item.icon"></i>
          </a>
          <h4 class="icon-box-title text-uppercase letter-space-3">
            <a class="text-theme-colored" href="#">{{item.titulo}}</a>
          </h4>
          <p>{{`${item?.extracto.substring(0,300)}...`}}</p>
          <a :href="item.urlButton"><button class="btn btn-theme-colored text-uppercase mt-10"> Leer Más</button></a>
      </div>
    </article>
</template>

<style lang="scss" scoped>
  @media (min-width: 1101px){
   .icon-box{
    margin-top: 1em;
     min-height: 450px;
   }
   .icon-box{
    -webkit-box-shadow: 2px 2px 5px #999;
  -moz-box-shadow: 2px 2px 5px #999;
  filter: shadow(color=#999999, direction=135, strength=2)
  }
  }
  @media (min-width: 992px) and (max-width: 1100px) {
    .icon-box{
    // margin-top: -90px;
    margin-top: 1em;
    min-height: 500px;
 
  }
  .icon-box{
    -webkit-box-shadow: 2px 2px 5px #999;
  -moz-box-shadow: 2px 2px 5px #999;
  filter: shadow(color=#999999, direction=135, strength=2)
  }

  // .flotan-cards-0{
  //   position:absolute;
  //   animation: levitar 10s ease infinite;
  //   //     -webkit-animation: levitar 5s ease infinite; /** Chrome & Safari **/
  //   //     -moz-animation: levitar 5s ease infinite; /** Firefox **/
  //   //     -o-animation: levitar 5s ease infinite; /** Opera **/
  //   }
  //   .flotan-cards-1{
  //     position:absolute;
  //     animation: levitar1 10s ease infinite;
  //   }
  //   .flotan-cards-2{
  //       position:absolute;
  //       animation: levitar2 10s ease infinite;
  //   }

  //   @keyframes levitar {
  //     0%{
  //       margin-top: 0;
  //        z-index: 0;
  //       transform: rotate(0deg);
  //     }
  //     25%{
  //        z-index: 0;
  //       transform: rotate(1deg);
  //     }
  //     50%{
  //       margin-top: -90px;
  //       z-index: 2;
  //       transform: rotate(0deg) scale(1.3);
  //     }
  //     75%{
  //        z-index: 0;
  //       transform: rotate(-1deg);
  //     }
  //     100% {
  //     margin-top: 0;
  //      z-index: 0;
  //     transform: rotate(0deg);
  //     }
  //   }
  //   @keyframes levitar2 {
  //     0%{
  //       margin-top: 0;
  //        z-index: 0;
  //       transform: rotate(1deg);
  //     }
  //     25%{
  //        z-index: 0;
  //       transform: rotate(0deg);
  //     }
  //     50%{
  //       margin-top: -90px;
  //        z-index: 2;
  //       transform: rotate(-1deg) scale(1.3);
  //     }
  //     75%{
  //        z-index: 0;
  //       transform: rotate(0deg);
  //     }
  //     100% {
  //     margin-top: 0;
  //      z-index: 0;
  //     transform: rotate(1deg);
  //     }
  //   }
  //   @keyframes levitar1 {
  //     0%{
  //       margin-top: -90px;
  //        z-index: 2;
  //       transform: rotate(-1deg) scale(1.3);
  //     }
  //     25%{
  //       transform: rotate(0deg);
  //        z-index: 0;
  //     }
  //     50%{
  //       margin-top: 0;
  //       z-index: 0;
  //       transform: rotate(1deg);
  //     }
  //     75%{
  //       transform: rotate(0deg);
  //        z-index: 0;
  //     }
  //     100% {
  //     margin-top: -90px;
  //     z-index: 2;
  //     transform: rotate(-1deg) scale(1.3);
  //     }
  //   }

 }
</style>
