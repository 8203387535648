<script>
export default {
  async mounted() {
    await this.$store.dispatch("getVideos");
  },
  computed: {
    videos() {
      return this.$store.getters.videos;
    },
  },
};
</script>

<template>
  <section class="layer-overlay overlay-dark-8 border-section" data-bg-img="images/pattern/pt19.jpg"
           style="padding-top:2em; padding-bottom:2em">

    <!-- <section id="dividerParallax" class="divider parallax"> -->
    <section class="videos-titulo">
      <h2 class="mt-0 line-height-1 text-center">
        <span class="text-theme-colored"> Videos</span>
      </h2>
      <!-- <section> -->
      <div class="videos-home">
        <template v-if="videos">
          <template v-for="(video, index) in videos" :key="index">
            <div class="video-home">
              <iframe
                  class="video-you"
                  width="100%"
                  height="100%"
                  :src="`https://www.youtube.com/embed/${video.youtube_token}`"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
            </div>
          </template>
        </template>
      </div>
    </section>
    <!-- </section> -->
  </section>
</template>

<style>
.border-section {
  border: 5px black;
}

.videos-titulo {
  margin-top: 2em;
}

.videos-home {
  /* margin: 1em; */
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.video-home {
  margin: 1em;
  height: 240px;
  width: 240px;
}

.video-you {
  border-radius: 0.35em;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 1500px) {


  .video-home {
    height: 480px;
    width: 480px;
  }
}

@media screen and (min-width: 1200px) {


  .video-home {
    height: 400px;
    width: 400px;
  }
}

@media screen and (max-width: 600px) {


  .video-home {
    height: 240px;
    width: 240px;
  }
}
</style>
