<script setup>
const clickForScrollMe = (id) => {
  let target = document.querySelector(`#${id}`);
  setTimeout(() => (target.scrollIntoView({behavior: 'smooth', block: 'center'})), 300);
}
defineProps({
  item: {
    type: Object,
    required: true,
  },
  index:{
    type: Number,
    required: true,
  }
});
</script>

<template>

  <div class="panel">
    <div class="panel-title">
      <a
      :id="`container-${item.id}-${index}`"
      @click="clickForScrollMe($event.target.id)" 
        class="active "
        data-toggle="collapse"
        data-parent="#accordeon-home-preguntas-frecuentes"
        :href="`#${item.id}-${index}`"
        :aria-expanded="index === 0"
      >
        <span class="open-sub">{{item.titulo}}</span></a>
    </div>
    <div
      :id="`${item.id}-${index}`"
      class="panel-collapse collapse"
      role="tablist"
      :class="{in : index === 0}"
      :aria-expanded="index === 0"
    >
      <div class="panel-content">
        <p> {{item.extracto}} </p>
      </div>
    </div>
  </div>
</template>

<style>
.panel-title{
  background: linear-gradient(to bottom, transparent, rgb(0, 0, 0));
}
</style>
