<script>
/*eslint-disable*/
// import emailjs from 'emailjs-com';
import {ad_url} from "../services/ApiReq.js";
import UltimasNoticiasFooter from '../views/Home/components/Noticias y Frecuentes/UltimasNoticiasFooter.vue';

export default {
  beforeMount() {
    this.ad = ad_url;
  },
  async mounted() {
    await this.$store.dispatch("getUltimasNoticias");
  },
  components: {
    UltimasNoticiasFooter,
  },
  // props:{
  //   item:{
  //     type: Object,
  //     required: true,
  //   } 
  // },
  data() {
    return {
      email: '',
      ad: "",
    }
  },
  methods: {
    facebookPoa() {
      window.open(`https://www.facebook.com/profile.php?id=100068426351096`)
    },
    instagramPoa() {
      window.open(`https://www.instagram.com/club_poa/`)
    },
    // sendEmail(e) {
    //   try {
    //     emailjs.sendForm(process.env.YOUR_SERVICE_ID, process.env.YOUR_TEMPLATE_ID, e.target,
    //     process.env.YOUR_USER_ID, {
    //       name: "Nuevo Suscriptor",
    //       email: this.email,
    //       message: " "
    //     })

    //   } catch(error) {
    //       console.log({error})
    //   }
    //   // Reset form field
    //   this.name = ''
    //   this.email = ''
    //   this.message = ''
    // },
  },
  computed: {
    UltimaNoticia() {
      return this.$store.getters?.ultimas_noticias.slice(0, 4);
    },
  },
};
</script>
<template>
  <footer id="footer" class="bg-black-222">
    <div class="container pt-70 pb-30">
      <div class="row border-bottom-black">
        <div class="col-sm-6 col-md-3">
          <div class="widget dark">
            <img class="mb-20" alt="" src="../../public/images/log17-200x110.png" style="border-radius: 0.25em"/>
            <p>CLUB ARGENTINO DE CRIADORES DEL PERRO OVEJERO ALEMÁN</p>
            <p>ADOLFO ALSINA 1163, (1088) BUENOS AIRES, ARGENTINA</p>
          </div>
        </div>
        <div class="col-sm-6 col-md-3">
          <div class="widget dark">
            <h5 class="widget-title line-bottom">Links</h5>
            <ul class="list angle-double-right list-border">
              <li><a href="/reglamentos/crianza-registro">Reglamentos</a></li>
              <li><a href="/calendario">Calendario</a></li>
              <li><a href="/informacion-util/aranceles">Aranceles</a></li>
              <li><a href="https://mpoa.clubpoa.com.ar/login" target="_blank">Inscripciones</a></li>
              <li><a href="/informacion-util/sede-administrativa-centro">Contacto</a></li>
            </ul>
          </div>
        </div>
        <div class="col-sm-6 col-md-3">
          <div class="widget dark">
            <h5 class="widget-title line-bottom">Contacto</h5>
            <div
                class="list-border clearfix"

            >
              <ul class="list-inline mt-5">
                <li class="m-0 pl-10 pr-10">
                  <i class="fa fa-phone text-theme-colored mr-5"></i>
                  <a class="text-gray"
                  >+54 (011) 4381-7368 / 9519 / 9596</a
                  >
                </li>
                <li class="m-0 pl-10 pr-10">
                  <i class="fa fa-envelope-o text-theme-colored mr-5"></i>
                  <a href="mailto:poa@clubpoa.com.ar" class="text-gray">poa@clubpoa.com.ar</a>
                </li>
                <li class="m-0 pl-10 pr-10">
                  <i class="fa fa-globe text-theme-colored mr-5"></i>
                  <a class="text-gray">www.clubpoa.com.ar</a>
                </li>
                <li class="m-0 pl-10 pr-10">
                  <i class="fa fa-facebook text-theme-colored mr-5"></i>
                  <a style="cursor: pointer" @click="facebookPoa" class="text-gray">Facebook POA Seguinos..</a>
                </li>
                <li class="m-0 pl-10 pr-10">
                  <i class="fa fa-instagram text-theme-colored mr-5"></i>
                  <a style="cursor: pointer" @click="instagramPoa" class="text-gray">Instragram POA Seguinos...</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-3">
          <div class="widget dark">

            <!-- <h5 class="widget-title line-bottom">Últimas Noticias</h5>
            <div class="latest-posts">
              <UltimasNoticiasFooter
                  v-for="(card, index) in UltimaNoticia"
                  :key="index"
                  :item="card"
              />
            </div> -->

            <div class="publicidad">
              <iframe :src="`${ad}/publicidad?type=button`" width="255" height="255"></iframe>
            </div>

          </div>
        </div>
      </div>
      <!-- <div class="row mt-30">
        <div class="col-md-5">
          <div class="widget dark">
            <h5 class="widget-title mb-10">Suscribite</h5>
            Mailchimp Subscription Form Starts Here
            <form
              id="mailchimp-subscription-form-footer"
              class="newsletter-form"
              @submit.prevent="sendEmail"
            >
              <div class="input-group">
                <input
                  type="email"
                  v-model="email"
                  name="EMAIL"
                  placeholder="Email"
                  class="form-control input-lg font-16  required email"
                  data-height="45px"
                  id="mce-EMAIL-footer"
                  style="height: 45px"
                />
                <span class="input-group-btn">
                  <button
                    data-height="45px"
                    class="btn btn-colored btn-theme-colored btn-xs m-0 font-14"
                    type="submit"
                  >
                    Suscribir
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-3">
          <div class="widget dark">
            <h5 class="widget-title mb-10">Conectados con vos</h5>
            <ul class="styled-icons icon-dark icon-circled icon-sm">
              <li>
                <a href="/"><i class="fa fa-facebook"></i></a>
              </li>
              <li>
                <a href="/"><i class="fa fa-twitter"></i></a>
              </li>
              <li>
                <a href="/"><i class="fa fa-youtube"></i></a>
              </li>
              <li>
                <a href="/"><i class="fa fa-instagram"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div> -->
    </div>
    <div class="footer-bottom bg-black-333">
      <div class="container pt-20 pb-20">
        <div class="row">
          <div class="col-md-6">
            <p class="font-11 text-black-777 m-0">
              Copyright Club Poa. All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>


<style scoped lang="scss">
.publicidad {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>