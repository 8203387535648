/*eslint-disable*/



export default {
  state: {
  },
  mutations: {

  },
  actions: {

  },
  getters: {
    isMobile() {
      return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    }
  },
};
