import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAwesomePaginate from 'vue-awesome-paginate';
import { library } from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faTrophy, faStar} from '@fortawesome/free-solid-svg-icons'
import Antd from 'ant-design-vue';
import VueCookies from 'vue-cookies'
import 'bootstrap-icons/font/bootstrap-icons.css'


library.add(faTrophy)
library.add(faStar)
import VueModal from './components/Modal'

createApp(App)
.use(VueModal)
.use(Antd)
.use(store)
.use(router)
.use(VueAwesomePaginate)
.use(VueCookies)
.component('font-awesome-icon', FontAwesomeIcon)
.mount('#app')
