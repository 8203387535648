<template>
  <template v-if="carruselimages">
    <template v-if="carruselimages.length">
      <Carousel
        :wrapAround="true"
        :transition="3000"
        :autoplay="1000"
        class="centro-header centro-orden"
        style="width: min-content !important"
      >
        <Slide v-for="(imagen, index) in carruselimages" :key="index">
          <div class="carousel__item-header">
            <img
              :alt="imagen.extracto"
              :src="imagen.imgUrl"
              class="img-carusel-header"
              v-on:click="gotoLink(imagen.urlButton)"
            />
          </div>
        </Slide>
      </Carousel>
    </template>
  </template>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    Carousel,
    Slide,
    // Pagination,
    // Navigation,
  },
  data() {
    return {
      carruselimages: [],
    };
  },
  async mounted() {
    /*
    await this.$store.dispatch("getImgTopbar");
    if (this.$store.getters?.imgTopbar)
      this.carruselimages = this.$store.getters.imgTopbar.map((v) => ({
        ...v,
        urlButton:
          v.contenido_bonus.find((x) => x.label === "URL EXTERNA")?.value || "",
        tipo: "poa",
      }));
    
    */
    await this.getPublicies({ type: ["carousel"] });
    this.publicies.map((x) => {
      this.carruselimages.push({
        imgUrl: x?.imagedata,
        imgMobileUrl: x?.imagedata,
        imgmobile_path: x?.imagedata,
        titulo: "",
        extracto: "",
        tipo: "publicidad",
        id: x?.campaign?.id,
        urlButton: x?.campaign?.link,
      });
    });
    /*
    let poas = this.carruselimages.filter((obj) => obj.tipo === "poa");
    let publicidades = this.carruselimages.filter(
      (obj) => obj.tipo === "publicidad"
    );
    let result = [];
    for (let i = 0; i < poas.length; i++) {
      result.push(poas[i]);
      if (publicidades.length > 0) {
        result.push(publicidades.shift());
      }
    }

    this.carruselimages = [...result, ...publicidades];
    */
  },
  computed: {
    ...mapGetters(["publicies"]),
    imagenes() {
      return [];
    },
  },
  methods: {
    ...mapActions(["getPublicies"]),
    gotoLink(l) {
      if (l != "") location.href = l;
    },
  },
};
</script>

<style>
/* .carousel__prev {
  left: 10px !important;
}
.carousel__next {
  right: 10px !important;
} */

/* Imagen comun */
/* .carousel{
  display: flex;
  align-items: center;
  flex: 1 1 auto !important;
  height: auto !important;
  width: auto !important;
} */
/* Imagen grande */

.carousel {
  flex: 1 1 auto !important;
  height: auto !important;
  width: auto !important;
}
.carousel__viewport {
  height: 100% !important;
  width: 100% !important;
}
.carousel__track {
  height: 100% !important;
  width: auto !important;
}
.carousel__item-header {
  display: flex;
  align-items: center;
  height: 100% !important;
  width: auto !important;
}
.img-carusel-header {
  height: auto !important; /* aca poner % que tan grande queres que ocupe espacio, auto es tamaño original*/
  max-height: 153px;
  width: auto !important;
  cursor: pointer;
}
@media (max-width: 994px) {
  .img-carusel1 {
    height: 100% !important;
    width: auto !important;
  }
}
</style>
