<script setup>
const clickForScrollMe = (id) => {
  let target = document.querySelector(`#${id}`);
  setTimeout(() => (target.scrollIntoView({behavior: 'smooth', block: 'center'})), 300);
}
defineProps({
  mesName: {
    type: String,
    required: true,
  },
  yearXHref:{
    type: String,
    required: true
  },
  autoExpand:{
    type: Boolean,
    default:false,
  }
});
</script>

<template>
  <div class="panel">
    <div class="panel-title">
      <a
        :id="`container-${mesName}-${yearXHref}`"
        @click="clickForScrollMe($event.target.id)" 
        style="font-size: 1.2em;"
        class="active "
        data-toggle="collapse"
        :data-parent="`#calendario-Arcordeon-${yearXHref}`"
        :href="`#${mesName}-${yearXHref}`"
        :aria-expanded="autoExpand"
      >
        <span class="open-sub">{{mesName}}</span></a
      >
    </div>
    <div
      :id="`${mesName}-${yearXHref}`"
      class="panel-collapse collapse"
      style="padding-bottom: 1em"
      :data-parent="`#calendario-Arcordeon-${yearXHref}`"
      role="tablist"
      :aria-expanded="autoExpand"
      :class="{in : autoExpand}"
    > 
      
      <slot name="innerAcordeon"></slot>
    </div>
  </div>
</template>


<style lang="scss" scoped>
tr{
  color: rgba(255, 255, 255, 0.85);
}:hover{
  color: white
}
.panel-title{
  background: linear-gradient(to bottom, transparent, rgb(0, 0, 0));
}
.color-borde-error{
    background: linear-gradient(to bottom, transparent, rgb(75, 69, 69));
    outline: none;
    border-bottom: 3px solid red;
    border-radius: 1%;
}
.color-borde-warning{
  background: linear-gradient(to bottom, transparent, rgb(75, 69, 69));
    outline: none;
    border-bottom: 3px solid yellow;
    border-radius: 1%;
}
.color-borde-success{
  background: linear-gradient(to bottom, transparent, rgb(75, 69, 69));
    outline: none;
    border-bottom: 3px solid green;
    border-radius: 1%;
}
</style>
