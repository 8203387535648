/*eslint-disable*/
//import html2pdf from 'html2pdf.js';

const backendUrl = "https://backend.clubpoa.com.ar:3001";


const arrayToSheet = async (data) => {
    const XLSX = await import('xlsx');

    const sheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, 'Sheet1');
    return workbook;
}

export default {
    state: {},
    mutations: {},
    actions: {
        async arrToXLSX({commit}, params) {
            const XLSX = await import('xlsx');
            let arr = structuredClone(params.arr.value);
            let orden = params.orden;
            let property = params.property;
            let filename = params.filename;
            // || LMartZ || \\

            // || Ordenamiento
            function sortBy(arr, property) {
                let sorted = arr.sort(compare);
                return sorted;

                function compare(a, b) {
                    let a_comp = a[property]?.toUpperCase();
                    let b_comp = b[property]?.toUpperCase();
                    if (a_comp < b_comp) return -1;
                    if (a_comp > b_comp) return 1;
                    return 0;
                }
            }

            if (property)
                arr = sortBy(arr, property)

            // || Ordenamiento de columnas
            if (orden)
                arr = arr.map((item) => {
                    let new_item = {};
                    orden.forEach((prop) => {
                        new_item[prop] = item[prop];
                    })
                    return new_item;
                });

            const xlsx = await arrayToSheet(arr);
            XLSX.writeFile(xlsx, params.filename ?? 'datos.xlsx');
        },
        async arrToPDFTable({commit}, arr) {
            // 36 chunks de 1500
            let body = ``;
            let index = 0;
            for (const item of arr.value) {
                body += `
                <tr>
                    <td>${item['Padre_codigo_letra']}${item['Padre_codigo']}</td>
                    <td>${item['Reproductor'] ?? 'N/A'}</td>
                    <td>${item['P'] ?? 'N/A'}</td>
                    <td>${item['DCF P'] ?? 'N/A'}</td>
                    <td>${item['Codo P'] ?? 'N/A'}</td>
                    <td>${item['Pelo P'] ?? 'N/A'}</td>
                    <td>${item['Madre_codigo_letra']}${item['Madre_codigo']}</td>
                </tr>`;
                index++;

                if (index >= 36) {
                    index = 0;
                }
            }

            let html = `
            <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>PC</th>
                        <th>PN</th>
                        <th>PS</th>
                        <th>PDCF</th>
                        <th>PDCFC</th>
                        <th>PP</th>
                        <th>MC</th>
                        <th>MN</th>
                        <th>MS</th>
                        <th>MDCF</th>
                        <th>MDCFC</th>
                        <th>MP</th>
                        <th>Servicio</th>
                        <th>Nacimiento</th>
                        <th>CM</th>
                    </tr>
                </thead>

                <tbody>
                    ${body}
                </tbody>
            </table>
            </div>

            <style>
                table {
                    
                }
            
                tr, th, td {
                    padding: .3em;
                    background: white;
                    color: black;

                    text-align: center;
                }

                td {
                    border-bottom: 1px solid rgba(0, 0, 0, .5);
                }

                .table-container {
                    /*width:1000px;
                    max-width:1000px;*/
                    background: white;
                }

                table {
                    /*margin-left: 3em;*/
                }

                .separator {
                    padding-bottom: 5em;
                }
            </style>
            `;
            /*
            html2pdf().set({
                filename: 'archivo.pdf',
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'px', format: 'a2', orientation: 'portrait' }
            }).from(html).save();
            */

            const response = await fetch(`${backendUrl}/files/pdf`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({html})
            });
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'archivo.pdf';
            link.click();
            URL.revokeObjectURL(url);
        }
    },
    getters: {},
};
