import { get } from "@/services/ApiReq";

/* eslint-disable */

export default {
  state: {
    adiestramiento: {
      torneos: null,
      years: null,
      calendarios: null,
      resultados: null
    },
  },
  mutations: {
    SET_ADIESTRAMIENTO(state, value) {
      state.adiestramiento.resultados = value.data.data;
      state.adiestramiento.resultados.total = value.data.total;
    },
    SET_ADIESTRAMIENTO_FILTERS(state, value) {
      state.adiestramiento.torneos = value.torneos;
      state.adiestramiento.years = value.years;
      state.adiestramiento.calendarios = value.calendarios;
      /*
        state.exposicion.torneos = value.torneos ? value.torneos : state.exposicion.torneos;
        state.exposicion.years = value.years ? value.years : state.exposicion.years;
        state.exposicion.calendarios = value.calendarios ? value.calendarios : state.exposicion.calendarios;*/
    },
    CLEAR_ADIESTRAMIENTO(state, payload) {
      const { torneos, years, calendarios, resultados } = payload;
      if (torneos) state.expos.torneos = null;
      if (years) state.adiestramiento.years = null;
      if (calendarios) state.adiestramiento.calendarios = null;
      if (resultados) state.adiestramiento.resultados = null;
    },
  },
  actions: {
    getAdiestramiento({ commit }, { url, params }) {
      return new Promise((resolve, reject) => {

        get(`sistema/exposicion/${url}`, { params })
          .then((res) => {


            commit("SET_ADIESTRAMIENTO", res);
            resolve();
          })
          .catch((err) => {
            reject(err);
            console.log(err)
          });
      });
    },
    getAdiestramientoFilters({ commit }, { params }) {
      return new Promise((resolve, reject) => {

        get(`sistema/filters/`, { params })
          .then((res) => {
            commit("SET_ADIESTRAMIENTO_FILTERS", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
            console.log(err)
          });
      });
    },
  },

  getters: {
    adiestramiento: (state) => state.adiestramiento,
  },
};


