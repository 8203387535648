<template>
  <template v-if="carruselhome">
    <template v-if="carruselhome.length">
      <Carousel
        :wrapAround="true"
        :transition="3000"
        :autoplay="10000"
        v-if="!isMobile()"
      >
        <Slide v-for="(info, index) in carruselhome" :key="index">
          <div class="carousel__item" v-if="info.imgUrl != null">
            <img
              class="img-carusel"
              :src="info.imgUrl"
              :alt="info.extracto.substring(0, 100)"
            />

            <div class="container-flotante-first-carrusel">
              <h1
                class="label-flotante-first-carrusel bg-dark-transparent-5 text-theme-colored"
              >
                {{ info.titulo.substring(0, 50) }}
              </h1>
              <!-- h3 class="value-flotante-first-carrusel bg-dark-transparent-4 text-white">{{info.extracto.substring(0,100)}}</h3 !-->
              <a class="button-flotante-first-carrusel btn btn-theme-colored" @click="Click(info)">
                  MAS INFO...
              </a>
            </div>
          </div>
        </Slide>
      </Carousel>

      <Carousel :wrapAround="true" :transition="3000" :autoplay="10000" v-else>
        <Slide
          v-for="(info, indexz) in carruselhome.filter(imgmovil)"
          :key="indexz"
        >
          <div class="carousel__item" v-if="info.imgMobileUrl != null">
            <img class="img-carusel" :src="info.imgMobileUrl" :alt="info.extracto.substring(0, 100)"/>

            <div class="container-flotante-first-carrusel">
              <h1 class="label-flotante-first-carrusel bg-dark-transparent-5 text-theme-colored">
                {{ info.titulo.substring(0, 50) }}
              </h1>
              <!-- h3 class="value-flotante-first-carrusel bg-dark-transparent-4 text-white">{{info.extracto.substring(0,100)}}</h3 !-->
              <a :href="info.urlButton">
                <button class="button-flotante-first-carrusel btn btn-theme-colored">
                  MAS INFO...
                </button>
                </a
              >
            </div>
          </div>
        </Slide>

      </Carousel>
    </template>
  </template>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";
import { mapActions, mapGetters } from "vuex";
import {ad_url} from "../../../services/ApiReq.js";

export default {
  components: {
    Carousel,
    Slide,
    // Pagination,
    // Navigation,
  },

  data() {
    return {
      carruselhome: [],
      ad: "",
    };
  },

  async mounted() {
    this.ad = ad_url;
    await this.$store.dispatch("getCarruselHome");
    this.carruselhome = this.$store.getters.carruselhome.map((v) => ({
      ...v,
      urlButton:
        v.contenido_bonus.find((x) => x.label === "URL EXTERNA")?.value ||
        v.url,
      tipo: "poa",
    }));

    await this.getPublicies({ type: ["bannertop"] });
    this.publicies.map((x) => {
      this.carruselhome.push({
        imgUrl: x?.imagedata,
        imgMobileUrl: x?.imagedata,
        imgmobile_path: x?.imagedata,
        titulo: "",
        extracto: "",
        tipo: "publicidad",
        id: x?.campaign?.id,
        urlButton: x?.campaign?.link,
      });
    });

    // || Intercalado
    let poas = this.carruselhome.filter((obj) => obj.tipo === "poa");
    let publicidades = this.carruselhome.filter(
      (obj) => obj.tipo === "publicidad"
    );
    let result = [];
    for (let i = 0; i < poas.length; i++) {
      result.push(poas[i]);
      if (publicidades.length > 0) {
        result.push(publicidades.shift());
      }
    }

    this.carruselhome = [...result, ...publicidades];
  },

  computed: {
    ...mapGetters(["publicies"]),
    /*
        {
          imgUrl: "blob",
          titulo: "",
          urlButton: "link",
        }
  */
  },
  methods: {
    ...mapActions(["getPublicies", "addClick"]),
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        document
          .getElementsByTagName("html")[0]
          .setAttribute("style", "scroll-behavior:smooth !important;");
        return true;
      } else {
        return false;
      }
    },
    imgmovil(a) {
      return a.imgmobile_path != null;
    },
    Click (image) {
      if(image.tipo === "publicidad") this.addClick({campaign_id: image.id});
      let a = document.createElement("a");
      a.href = image.urlButton;
      a.target = "blank";
      a.click();
      a.remove();
    }
  },
};
</script>
<style>
.button-flotante-first-carrusel {
  position: absolute;
  bottom: 20%;
  left: 10%;
  -webkit-box-shadow: 2px 2px 5px #999;
  -moz-box-shadow: 2px 2px 5px #999;
  filter: shadow(color=#999999, direction=135, strength=2);
}
.label-flotante-first-carrusel {
  font-size: 2.5em;
  position: absolute;
  border-radius: 0.15em;
  top: 5%;
  left: 5%;
  padding: 0.25em;
  -webkit-box-shadow: 2px 2px 5px #999;
  -moz-box-shadow: 2px 2px 5px #999;
  filter: shadow(color=#999999, direction=135, strength=2);
}
.value-flotante-first-carrusel {
  position: absolute;
  font-size: 2em;
  border-radius: 0.15em;
  left: 25%;
  padding: 0.15em;
  -webkit-box-shadow: 2px 2px 5px #999;
  -moz-box-shadow: 2px 2px 5px #999;
  filter: shadow(color=#999999, direction=135, strength=2);
}
.button-flotante-first-carrusel {
  animation-duration: 10000ms;
  animation-name: giraencinculos;
  animation-iteration-count: infinite;
}
.label-flotante-first-carrusel {
  animation-duration: 10000ms;
  animation-name: caedearriba;
  animation-iteration-count: infinite;
}
.value-flotante-first-carrusel {
  margin-right: 1em;
  animation-duration: 10000ms;
  animation-name: expande;
  animation-iteration-count: infinite;
}
@keyframes expande {
  0% {
    width: 0;
    z-index: -100000;
  }
  25% {
    width: 10%;
    z-index: 0;
    overflow: hidden;
  }
  30% {
    width: 60%;
    z-index: 0;
  }
  95% {
    width: 60%;
    z-index: 0;
  }
  99% {
    width: 10%;
    z-index: 0;
    overflow: hidden;
  }
  100% {
    width: 0;
    z-index: -100000;
  }
}
@keyframes caedearriba {
  0% {
    margin-top: -15%;
  }
  25% {
    margin-top: -15%;
  }
  30% {
    margin-top: 0%;
  }
  95% {
    margin-top: 0%;
  }
  100% {
    margin-top: -12%;
  }
}
@keyframes giraencinculos {
  0% {
    border-radius: 100%;
    z-index: -100000;
  }
  25% {
    z-index: 0;
    transform: rotate(360deg);
  }
  30% {
    border-radius: 0;
    transform: rotate(0deg);
  }
  95% {
    z-index: 0;
  }
  100% {
    z-index: -100000;
  }
}

.carousel {
  flex: 1 1 auto !important;
  height: auto !important;
  width: auto !important;
}
.carousel__viewport {
  height: 100% !important;
  width: 100% !important;
}
.carousel__track {
  height: 100% !important;
  width: auto !important;
}
.carousel__item {
  display: flex;
  align-items: center;
  height: 100% !important;
  width: auto !important;
}
.img-carusel {
  height: 100% !important;
  width: auto !important;
  object-fit: cover;
}
</style>


