<template>
  <vue-modal @close="$emit('close')" ref="modal" size="md">
    <template #title>{{item?.titulo}}</template>
    <template #body>
      <div class="container1">
        <h2 class="text-theme-colored">{{item?.extracto}}</h2>
        <div id="POAFOTO" class="col-md-5 FOTO">
          <div>
            <img :src="item.imgUrl" class="img-fullwidth" />
          </div>
        </div>
        <div class="texto" v-html="`${item?.texto}`"></div>
        
        <div id="grid3" class="gallery-isotope grid-4 gutter clearfix">
          <div id="imagenesGaleria" class="col-md-12">
            <GaleriaImagenes :images="imagenes"/>
          </div>
        </div>
        <div v-for="contenido_bonu in item.contenido_bonus" :key="contenido_bonu">
          <div v-if="contenido_bonu.label === 'URL EXTERNA'">
            <a :href="contenido_bonu.value" target="_blank"><button class="btn btn-theme-colored text-uppercase" type="submit">Click aqui para mas Info...</button></a>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <button class="btn btn-theme-colored" @click="close" type="submit">
        Cerrar
      </button>
    </template> 
  </vue-modal>
</template>

<script>
import GaleriaImagenes from "@/components/GaleriaImagenes.vue";
export default {
  components: {
    GaleriaImagenes
  },
  props: {
   item:{
    type: Object,
    default: null
   }
  },
  data: () => ({
   
  }),
  computed:{
    imagenes(){
      let arr = []
      this.item.galerias.forEach((galeria) => {
    arr = galeria.files.reduce((acc, file) => {
      if (file.type.includes('image')) acc.push(file.url);
      return acc;
    }, []);
  });
  return arr;
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>
<style lang="scss" scoped>
.FOTO{
  width: 80%;
  padding: 10px;
  & img{
    width: 100%;
    max-height: 350px;
    border-radius: 0.5em;
  }
}
.container1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.btn{
  box-shadow: 0 0 5px 0 #a8a4a4fc;
  margin: 5px;
}
.border-img{
  border-radius: 0.3rem; 
   box-shadow: 0 0 10px 0 #a8a4a4fc;
}
</style>
