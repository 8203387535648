<script>
export default {

  name: 'ContactUs',
  data() {
    return {
      name: '',
      email: '',
      message: '',
      cargando: false,
      cartelEmergente: false,
    }
  },
  methods: {
    mostrarCartelEmergente(){
      this.cartelEmergente = true;
      setTimeout(() => {
       this.cartelEmergente = false;
      }, "8000")
    },
    sendMail(){
      this.cargando = true
      const form = {
        to: 'poa@clubpoa.com.ar',
        from: null,
        text: null,
        subject: 'Contacto desde la Web'

      }
      if(this.email){
        form.from= this.email
      }
      if(this.message){
        form.text= this.message
      }
       this.$store.dispatch('SendMailBack', form).then(()=>{
        this.mostrarCartelEmergente(),
        this.cargando = false,
        this.email = '',
        this.message='',
        this.name=''
       });
    }
  }
}

</script>

<template>
  <div class="col-md-6 bg-light" id="contacto-Poa">
    <div class="p-70">
      <div class="row">
        
        <template v-if="cartelEmergente">
            <div class="alert alert-success vibra-cartel-emergente" role="alert">
            <p class="line-height-1 font-18 text-center">Mensaje enviado con Exito!</p>
          </div>
        </template>
        
        <div class="col-md-10">
          <h2 class="mt-0 line-height-1">
            <span>
              <span class="text-theme-colored">Contacto</span></span
            >
          </h2>
          <p>
            Cualquier duda no dudes en escribirnos por medio del
            siguiente formulario, te responderemos lo antes posible.
          </p>
          <form
            ref="form" @submit.prevent=""
            id="getaquote_form"
            name="getaquote_form"
            class="form-transparent mt-20"
            method="post"
          >
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group mb-10">
                  <input
                   v-model="name"
                    name='nombre'
                    id="nombre"
                    class="form-control"
                    type="text"
                    required=""
                    placeholder="Nombre"
                    aria-required="true"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group mb-10">
                  <input
                  v-model="email"
                    name='email'
                    id="email"
                    class="form-control required email"
                    type="email"
                    placeholder="Email"
                    aria-required="true"
                  />
                </div>
              </div>
              <!-- <div class="col-sm-6">
                <div class="form-group mb-10">
                  <input
                    name='telefono'
                    id="telefono"
                    class="form-control"
                    type="tel"
                    placeholder="Teléfono"
                    aria-required="true"
                  />
                </div>
              </div> -->
            </div>
            <div class="form-group mb-10">
              <textarea
               v-model="message"
                name='mensaje'
                id="mensaje"
                class="form-control required"
                placeholder="Mensaje"
                rows="5"
                aria-required="true"
              ></textarea>
            </div>
            <div class="form-group mb-0 mt-20">
              <input id="form_botcheck" name="form_botcheck" class="form-control" type="hidden" value="">
              <button @click="sendMail" type="submit" class="btn btn-dark btn-theme-colored text-uppercase" :class="{disabled: cargando}">{{ !cargando ? 'Enviar Mensaje' : 'Enviando Mensaje...' }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.contacto-poa{
  height: 100% !important;
}

.vibra-cartel-emergente{
  animation: vibrate 0.5s infinite;
}
@keyframes vibrate {
  0%{
    transform: rotate(0deg);
  }
  25%{
    transform: rotate(-0.5deg);
  }
  50%{
    transform: rotate(0deg) scale(0.99);
  }
  75%{
    transform: rotate(0.5deg);
  }
  100% {
    transform: rotate(-0.5deg);
  }
}
</style>
