<template>
  <div class="blog-content">
    <a :href="`${etiquete || item.url}`">

      <img :src="item.imgUrl ?? 'https://backend.clubpoa.com.ar:3001/images/logo%20pag%20principal.jpg'">
      <h2 v-if="item?.titulo">{{ item?.titulo }}</h2>
    </a>
    <div class="wrapped-text">
      <em v-if="item?.extracto">{{ item?.extracto?.substring(0, 50) }}</em>
    </div>
    <div class="texto wrapped-text" v-if="item?.texto" v-html="`${item?.texto?.substring(0,200)}. . .`"></div>
    <a class="link" :href="`${etiquete || item.url}`">Leer Más </a>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  mounted () {
    this.$emit("counter");
  },
  computed: {
    etiquete() {
      const asd = this.item?.contenido_bonus.find(x => (x.label == 'URL EXTERNA'))?.value || null
      return asd
    },
    isMobile() {
      return this.$store.getters.isMobile;
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.blog-content {
  width: 100%;


  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin-bottom: 1em;
  }

  h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 325px;
  }

  .texto {
    margin-bottom: 2em;
  }

  .link {
    position: absolute;
    bottom: -45px;
    margin-bottom: 1em;

    background: #65C178;
    padding: 0.2em 1em;
    color: white;
    border-radius: 3px;

    &:hover {
      background: #46AD5B;
    }
  }
}
</style>

<style>
.wrapped-text {

}
</style>