export const findParent = (obj, category) => {
  const padres = Object.keys(obj)
  if (padres.includes(`${category.id_padre}`)){
    obj[category.id_padre].submenu[category.id] = {...category, submenu: {}};
  } else{
    padres.forEach(padre => {
      if (Object.keys(obj[padre].submenu).length > 0){
        findParent(obj[padre].submenu, category)
      }
    })
  }
  return obj
}
