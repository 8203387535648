<script>
/* eslint-disable */
import { useRouter, useRoute } from "vue-router";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import MenuCategory from "./MenuCategory.vue";
import CarouselTopbar from "./CarouselTopbar.vue";
export default {
  setup() {
    const store = useStore();
    const menu = computed(() => Object.entries(store.getters.menu));
    const router = useRouter();
    const route = useRoute();
    onMounted(() => {
      store.dispatch("getMenu");
      /*menu_open.value.addEventListener('click', () => {
        document.getElementsByClassName('navegacion-que-acompania')[0].setAttribute('style', 'display: block;');
      });*/
    });
    const irAlHome = () => {
      router.push({ name: "Home" });
    };
    return {
      store,
      menu,
      route,
      router,
      irAlHome,
    };
  },
  mounted() {
    //console.log(this.route.path == '/');
  },
  data() {
    return {
      isMobileNavOpen: false,
    };
  },
  computed: {},
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        document
          .getElementsByTagName("html")[0]
          .setAttribute("style", "scroll-behavior:smooth !important;");
        //console.log("Soy Mobile")
        return true;
      } else {
        //console.log("No Soy Mobile")
        return false;
      }
    },
    showMobileNav() {
      document
        .getElementsByClassName("navegacion-que-acompania")[0]
        .setAttribute(
          "style",
          `display:${this.isMobileNavOpen ? "none" : "block"};`
        );
      document
        .getElementsByClassName("c-navegacion-que-acompania")[0]
        .setAttribute(
          "style",
          `display:${this.isMobileNavOpen ? "none" : "block"};`
        );
      this.isMobileNavOpen = !this.isMobileNavOpen;
    },
    MiPOA() {
      this.router.push({ name: "MiPOA" });
    },
  },
  components: {
    MenuCategory,
    CarouselTopbar,
  },
};
</script>

<template>
  <header class="header" v-if="!isMobile()">
    <div class="bg-theme-colored topbarPOA">
      <ul
        class="styled-icons icon-dark icon-circled icon-theme-colored icon-sm pull-right flip sm-pull-none sm-text-center iconos-topbar1"
      >
        <li>
          <a class="agrandar-home" href="/"
            ><i class="fa fa-home text-white"></i
          ></a>
        </li>
      </ul>
      <ul
        class="styled-icons icon-dark icon-circled icon-theme-colored icon-sm pull-right flip sm-pull-none sm-text-center iconos-topbar"
      >
        <li>
          <a
            href="https://web.facebook.com/people/POA-Club-Argentino-de-Criadores-del-Perro-Ovejero-Alem%C3%A1n/100068426351096/"
            ><i class="fa fa-facebook text-white"></i
          ></a>
        </li>
        <li>
          <a href="https://www.instagram.com/club_poa/"
            ><i class="fa fa-instagram text-white"></i
          ></a>
        </li>
      </ul>
    </div>

    <div class="header-nav" id="HeaderNavFondo">
      <div class="video-poa logo-orden">
        <video
          @click="irAlHome"
          src="../../public/images/Logo_Poa.mp4"
          loop
          autoplay
          muted
          width="160"
          height="180"
          id="logo-poa"
        />
        <img class="img-poa" src="../../public/images/palabrasPOA2.png" />
      </div>

      <CarouselTopbar />

      <div class="buttons-headers botones-orden">
        <!-- <button class="boton-1 btn btn-theme-colored text-uppercase disabled" @click="mipoa">Mi Poa</button> -->
        <div class="boton-1">
          <button
            class="btn boton-11 btn-theme-colored text-uppercase disabled"
            @click="mipoa"
          >
            <div class="texto-mi-poa">Mi Poa</div>
            <img class="img-mi-poa" src="../../public/images/logomipoa.svg" />
          </button>
        </div>
        <a href="/#contacto-Poa">
          <button
            class="btn btn-theme-colored text-uppercase"
            @click="sersocio"
          >
            HACETE SOCIO
          </button></a
        >
      </div>
    </div>
  </header>

  <header class="header" v-else>
    <div class="bg-theme-colored topbarPOA">
      <ul
        class="styled-icons icon-dark icon-circled icon-theme-colored icon-sm flip sm-pull-none sm-text-center iconos-topbar1"
      >
        <li>
          <a class="agrandar-home" href="/"
            ><i class="fa fa-home text-white"></i
          ></a>
        </li>
      </ul>
      <ul
        class="styled-icons icon-dark icon-circled icon-theme-colored icon-sm pull-right flip sm-pull-none sm-text-center iconos-topbar"
      >
        <li class="li_topbar_mobile">
          <a
            href="https://web.facebook.com/people/POA-Club-Argentino-de-Criadores-del-Perro-Ovejero-Alem%C3%A1n/100068426351096/"
            ><i class="fa fa-facebook text-white"></i
          ></a>
        </li>
        <li class="li_topbar_mobile">
          <a href="https://www.instagram.com/club_poa/"
            ><i class="fa fa-instagram text-white"></i
          ></a>
        </li>
        <li class="li_topbar_mobile">
          <div>
            <img
              class="img-mi-poa-mobile"
              src="../../public/images/logomipoablack.svg"
            />
            <a href="#contacto-Poa">Mi POA</a>
          </div>
        </li>
        <li class="li_topbar_mobile">
          <div>
            <img
              class="img-mi-poa-mobile"
              src="../../public/images/mail-icon.svg"
              style="width: 1.2em"
            />
            <a href="javascript:void(0)">Hacete Socio</a>
          </div>
        </li>
      </ul>
    </div>
    <div class="header-nav-mobile" id="HeaderNavFondo">
      <div class="video-poa-mobile logo-orden">
        <video
          @click="irAlHome"
          src="../../public/images/Logo_Poa.mp4"
          loop
          autoplay
          muted
          width="60"
          id="logo-poa"
        />
        <p class="text-center">
          CLUB ARGENTINO DE CRIADORES DEL PERRO OVEJERO ALEMÁN
        </p>

        <CarouselTopbar />
      </div>
    </div>
  </header>
  <!-- :class="esHome ? 'header-falso' : 'navegacion-que-acompania'" -->
  <div class="c-navegacion-que-acompania" id="HeaderNavFondo">
    <img
      class="imagen-menu-responsive"
      src="../../public/images/menu-icon.png"
      alt=""
    />
    <div class="header-nav navegacion-que-acompania" id="HeaderNavFondo">
      <div class="nav-header">
        <MenuCategory
          v-for="category in menu"
          :key="category[0]"
          :category="category[1]"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.boton-1 {
  display: flex;
  flex-direction: column;
}
.img-poa {
  max-width: 150px;
}
.agrandar-home {
  font-size: 2em !important;
}
.topbarPOA {
  display: flex;
  justify-content: space-between;
}
.iconos-topbar {
  margin-right: 5px;
}
.iconos-topbar1 {
  margin-left: 5px;
}
.header-nav {
  // min-width: 315px;
  display: flex;
  justify-content: space-between;
  //justify-content: center;
  align-items: stretch;
}
.header-nav-mobile {
  display: flex;
  align-items: stretch;
}
.video-poa-mobile {
  width: auto;
  height: auto;
  margin: 1em;
  display: flex;
  // flex-direction: column;
  justify-content: left;
  align-items: stretch;

  height: 4em;

  & > video {
    width: 4em;
  }

  & > p {
    margin: 0;
    width: 45%;
    color: black;
    font-weight: 800;
  }
}
.video-poa {
  width: auto;
  margin: 1em;
  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
}
#logo-poa {
  border-end-end-radius: 5.6em;
  border-end-start-radius: 5.6em;
  cursor: pointer;
}
.centro-header {
  display: flex;
}
.nav-header {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  list-style: none;
  width: 100%;
  flex-wrap: wrap;
}
.buttons-headers {
  margin-right: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.header-falso {
  display: none;
}
.header-fixed-stikybar-home {
  top: 0;
  position: fixed;
  z-index: 2;
  width: 100%;
}
.header-falso-home {
  z-index: -99;
}
.navegacion-que-acompania {
  position: sticky !important ;
  width: 100% !important;
  top: 0 !important;
  min-height: max-content;
  z-index: 2;
}
.c-navegacion-que-acompania {
  position: sticky !important ;
  width: 100% !important;
  top: 0 !important;
  min-height: max-content;
  z-index: 2;
}
.img-mi-poa {
  display: none;
  height: 35px;
  width: 35px;
  min-height: 35px;
  min-width: 35px;
}
.imagen-menu-responsive {
  display: none;
  position: absolute;
  right: 10%;
  top: 50%;
  transform: translateX(-10%) translateY(-50%);
  max-height: 35px;
  z-index: 9999999999999999999999;
}
.li_topbar_mobile {
  width: fit-content;
  margin-right: 0.5em;
  margin-top: 0;
  padding-left: 0px;
  padding-right: 0px;

  &:hover {
    background-color: #46ad5b !important;
  }

  & > div {
    width: fit-content;
    min-height: 30px;
    display: flex;
    justify-content: space-between;

    & > a {
      margin-left: 0.4em !important;
      width: 100% !important;
      //pointer-events: none;
      background: none !important;
      background-color: none !important;

      margin-right: 0 !important;

      &:hover {
        border-radius: 0 !important;
        background: none !important;
        background-color: none !important;
      }
    }

    & > img {
      width: 1em;
    }
  }
}
// @media (max-width: 2000px) and (min-width: 1123px){
//   .video-poa{
//     max-width: 300px;
//   }
// }
@media (max-width: 1140px) and (min-width: 994px) {
  .video-poa {
    // max-width: 160px;
    width: auto;
    flex-direction: column;
  }
}
@media screen and (max-width: 994px) {
  .boton-1 {
    flex-direction: row;
    margin-right: 0.25em;
  }
  .btn {
    min-width: 200px !important;
  }
  .header-nav {
    align-items: center;
  }
  .buttons-headers {
    margin-top: 1em;
    flex-direction: row;
  }
  .header-nav {
    flex-direction: column;
  }
  .botones-orden {
    order: 1;
  }
  .logo-orden {
    order: 2;
  }
  .centro-orden {
    order: 3;
  }
  .img-carusel-header {
    height: 100% !important;
    cursor: pointer;
  }
  .carousel__item-header {
    display: flex;
    align-items: center;
    width: auto !important;
  }
}

@media screen and (max-width: 683px) {
  .nav-header {
    display: flex;
    flex-direction: column;
    align-items: stretch !important;
  }
  .img-carusel-header {
    width: auto !important;
    cursor: pointer;
  }
  .carousel__item-header {
    display: flex;
    align-items: center;
    width: auto !important;
  }
}

@media screen and (max-width: 430px) {
  .img-carusel-header {
    height: 100% !important;
    cursor: pointer;
  }
  .carousel__item-header {
    display: flex;
    align-items: center;
    height: 100% !important;
    width: auto !important;
  }

  .img-mi-poa {
    display: block;
  }
  .texto-mi-poa {
    display: none;
  }
  // .boton-11{
  //   max-width: 70px !important;
  // }
  .buttons-headers {
    justify-content: center;
  }
  .imagen-menu-responsive {
    display: block;
  }
  .navegacion-que-acompania {
    display: none;
  }
  .c-navegacion-que-acompania {
    min-height: 50px;
  }
  .c-navegacion-que-acompania:hover {
    .imagen-menu-responsive {
      display: none;
    }
    .navegacion-que-acompania {
      display: block;
    }
  }
  .text-center {
    font-size: 0.8em;
    margin-left: 5px;
  }
  .section-content {
    margin-top: 25px;
  }
}
</style>
