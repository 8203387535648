import {get} from "@/services/ApiReq";


export default {
  state: {
    torneos: [],
    torneo: {},
  },
  mutations: {
    SET_TORNEOS(state, value) {
      state.torneos = value;
    },
    SET_TORNEO(state, value) {
      state.torneo = value;
    },
  },
  actions: {
    getTorneos({ commit }) {
      return new Promise((resolve) => {
        get(`contenidos/torneo`)
          .then((res) => {
            commit("SET_TORNEOS", res.data);
            resolve();
          })
          .catch((err) => {console.log(err)});
      });
    },
    getTorneo({ commit }, url) {
      const tag = url.split("/").pop();
      return new Promise((resolve) => {
        get(`contenidos/torneo/?url=${tag}`)
            .then((res) => {
              commit("SET_TORNEO", res.data[0]);
              resolve();
            })
            .catch(() => {resolve()});
      });

    },
    getTorneoOthers({ commit }, {tipo,url}) {

      return new Promise((resolve) => {
        get(`contenidos/${tipo}/?url=${url}`)
            .then((res) => {
              commit("SET_TORNEO", res.data[0]);
              resolve();
            })
            .catch(() => {resolve()});
      });

    },

  },

  getters: {
    torneos: (state) => state.torneos,
    torneo: (state) => state.torneo,
  },
};
