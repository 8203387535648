<script>
/*eslint-disable*/
import Blog from "./Blog.vue";
import {ad_url} from '../../../../services/ApiReq.js'

export default {
  name: "Ablog",
  data() {
    return {
      blogs: [],    
      ad: "",
    };
  },
  components: {
    Blog,
  },
  computed: {
    contenidos() {
      return this.$store.getters.contenidos;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
  },
  async mounted() {
    this.ad = ad_url;
    const tipo = "blog";
    await this.$store.dispatch("getContenidos", tipo);

    let blogtype = this.contenidos.filter((x) => {
      if (x?.tipo_contenido?.nombre === `BLOG`) return x;
    })
  
    let size = 3;
    for (let i = 0; i<blogtype.length; i+=size) {
      this.blogs.push(blogtype.slice(i, i + size));
    }
  },
  methods: {
    
  },
};
</script>

<template>
    <div class="blogs-container" v-if="contenidos">
    <div v-for="(set, index) in Object.keys(blogs)" :key="index" class="set-container">
      <div class="blogs-content" :class="isMobile ? 'mobile-blogs-content' : ''">
        <div v-for="(blog, subindex) in blogs[index]" :key="subindex" class="blog-container" :class="isMobile ? 'mobile-blog-container' : ''">
          <Blog
            :item="blog"
          >
          </Blog>
        </div>
      </div>
      <div class="publicidad-horizontal">
        <iframe :src="`${ad}/publicidad?type=horizontal`" width=770 height="150"></iframe>
      </div> 
    </div>
  </div>
</template>

<style lang="scss" scoped>
.blogs-container {
  width: 100%;

  .blogs-content {
    /*
    width: 80%;
    height: 100%;
    margin: auto;

    display: grid;
    place-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    */

    width: 80%;
    display: flex;
    justify-content: space-between;

    .blog-container {
      width: 30%;
      min-height: 480px;
      max-height: 480px;

      margin-bottom: 5em;
      position: relative;
    }
  }

  .mobile-blogs-content {
    display: block;
  }

  .mobile-blog-container {
    width: 100% !important;
  }

  .publicidad-horizontal {
    margin: 2em auto;
    width: 770px;
    height: fit-content;

    display: flex;
    align-items: center;
    justify-content: center;

  }
}

.set-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
