<script>
export default {
  props:{
    images: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    img_zoom: null,
    video_zoom: null,
    zoom: false,
    arrayVideos: ['mp4', 'mkv', 'mov', 'mwv', 'avi', 'webm', 'flv', 'qt']
  }),
  methods:{
     onZoom(type, item){
      if(type === 'img'){
        return this.img_zoom = item, this.zoom = true
      }
      if(type === 'video'){
        return this.video_zoom = item, this.zoom = true
      }
      if(type === 'close'){
        if( item.target.id !== 'video-zoom')
        {return this.img_zoom = null, this.video_zoom = null, this.zoom = false}
      }
    },
  },
};
</script>

<template>
  <div class="gallery-item photography" v-for="(i, index) in images" :key="index">
    <div class="style-galeria-img style-galeria-video position-relative" >  
      <template v-if="arrayVideos.some(x => i.includes(x))">
        <video :src="i" autoplay muted loop></video>
        <i class="fa fa-plus icon-search-img position-absolute" @click="onZoom('video', i)"></i>
      </template>
      <template v-else>
        <img class="img-fullwidth" :src="i" alt="project"/>
        <i class="fa fa-plus icon-search-img position-absolute" @click="onZoom('img', i)"></i>
      </template>
    </div>
  </div>
  <div v-if="zoom" class="position-fixed zoom-items" @click="onZoom('close', $event)">
    <img class="position-absolute zoom-items-img" v-if="img_zoom" :src="img_zoom" alt="">
    <video id="video-zoom" class="position-absolute zoom-items-video" v-if="video_zoom" :src="video_zoom" controls></video>
  </div>

<!-- 
 Como Usarlo
  <div class="col-md-5">
    <h2 class="mt-0 line-height-1">
      <span class="text-theme-colored"> Galeria</span>
    </h2>
  </div>
  <div id="grid3" class="gallery-isotope grid-4 gutter clearfix">
    <div id="imagenesGaleria" class="col-md-12">
      <GaleriaImagenes :images="images" />
    </div>
  </div>

 -->


</template>

<style>
.img-fullwidth{
  height: 100%;
}
.thumb{
  width: 100%;
  height: 100%;
}
.gallery-item{
  width: 100% !important;
  height: 250px !important;
  min-height: 250px !important;
  max-height: 250px !important;
  padding-right: 0.5em !important;
  padding-bottom: 0.5em !important;
  margin-bottom: 0.5em !important;

}


.style-galeria-img{
  width: 100% !important;
  height: 250px !important;
  min-height: 250px !important;
  max-height: 250px !important;
  padding-right: 0.5em !important;
  padding-bottom: 0.5em !important;
  margin-bottom: 0.5em !important;
}
.style-galeria-video{
  width: 100% !important;
  height: 250px !important;
  min-height: 250px !important;
  max-height: 250px !important;
  padding-right: 0.5em !important;
  padding-bottom: 0.5em !important;
  margin-bottom: 0.5em !important;
}
.style-galeria-img img{
  height: 100%;
  width: 100%;
  object-fit: cover;
   cursor: pointer;
}
.style-galeria-video video{
  height: 100%;
  width: 100%;
  object-fit: cover;
   cursor: pointer;
}
.style-galeria-img:hover > img, .style-galeria-video:hover > video{
  filter: blur(10px);
}
.style-galeria-img:hover > .icon-trash-delete-img, .style-galeria-video:hover > .icon-trash-delete-video,
.style-galeria-img:hover > .icon-search-img, .style-galeria-video:hover > .icon-search-video{
  z-index: 1;
}


.icon-trash-delete-img:hover, .icon-trash-delete-video:hover, .icon-search-img:hover, .icon-search-video:hover{
  color: white;
  border: 4px solid white;
  background-color: #65c178;
  scale: 1.5;
  filter: drop-shadow(3px 5px 2px rgb(0, 0, 0 / 0.4));
}

.icon-search-img, .icon-search-video{
  font-weight: 1000;
  border-radius: 50%;
  border: 4px solid #65c178;
  padding: 1em;
  left: 50%;
  top: 50%;
  color: #65c178;
  transform: translateX(-50%) translateY(-50%);
  z-index: -1;
  transition: all 0.5s;
}

.zoom-items{
  z-index: 5;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: grid;
  place-items: center;
}
.zoom-items-img{
  max-height: 70vh;
  max-width: 70vw;
}
.zoom-items-video{
  max-height: 50vh;
  max-width: 50vw;
}

</style>