/* eslint-disable */

import process from "../../.eslintrc";

const axios = require("axios");
const backendUrl = "https://backend.clubpoa.com.ar:3001";
export default {
    state: {
        examenes: {
            extranjeros: []
        }
    },
    mutations: {
        SET_EXTRANJEROS(state, params) {
            state.examenes.extranjeros = params;
        }
    },
    actions: {
        getExtranjeros({commit}) {
            axios.post(`${backendUrl}/sistema/examenes/extranjeros`).then((response) => {
                commit("SET_EXTRANJEROS", response.data.rows);
            });
        }
    },
    getters: {
        extranjeros(state) {
            return state.examenes.extranjeros;
        },
    },
}


