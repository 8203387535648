<template>
  <div class="dark">
    <div id="wrapper">
      <div id="preloader" class="gradiente-spinner" style="z-index: 100;">
        <div id="spinner" class="spinner large-icon">
          <Spinner/>
          <h2 class="line-height-1 font-18">Cargando</h2>
        </div>
        <!-- <div id="disable-preloader" class="btn btn-default btn-sm">Disable Preloader</div> -->
      </div>
      <Header/>
      <router-view/>
      <Footer/>
    </div>
  </div>
</template>

<script>
import Spinner from "./components/Spinner.vue"
import Footer from './components/Footer.vue';
import Header from './components/Header.vue';

import {mapGetters} from 'vuex';

export default {
  async mounted() {
    // if (this.extranjeros === [] || this.extranjeros)
    // await this.$store.dispatch("getExtranjeros");
  },
  computed: {
    ...mapGetters(["extranjeros"])
  },
  components: {
    Spinner,
    Footer,
    Header
  }
}
</script>

<style>
.publicidad, .publicidad-horizontal {
  display: none !important;
}

:root {
  --verde-poa: #65c178;
}

#app {
  overflow-x: hidden;
}

.gradiente-spinner {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(101, 193, 120, 0.4), rgba(0, 0, 0, 0.8) 100%);
}

#spinner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: initial;
}

#preloader {
  z-index: 100;
}

.poa-btn {
  cursor: pointer;
  color: #65c178;
  padding: .5em 1em;

  border: 1px solid #65c178;
  user-select: none;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #212021;
}

::-webkit-scrollbar-thumb {
  background: #65c079;
  border-radius: 10px;

  transition: .2s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #55a366;
}

::-webkit-scrollbar-corner {
  background: #212021;
}

</style>
