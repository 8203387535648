import {get} from "@/services/ApiReq";

export default {
  state: {
    alerta_home: [],
    features: [],
    video: [],
    videos: [],
    card_videos: [],
    preguntas_frecuentes: [],
    icon_contacto: [],
    ultimas_noticias: [],
    ultimas_noticias_drop: [],
    galeria_home: {},
    card_comite: [],
    resultados: [],
    imgTopbar: [],
    carruselhome: [],
  },
  mutations: {
    SET_FEATURES(state, value) {
      state.features = value;
    },
    SET_VIDEO(state, value) {
      state.video = value;
    },
    SET_VIDEOSHOME(state, value) {
      state.videos = value;
      state.videos = state.videos?.filter((e) => {
        return e.name.includes("HOME")
      })
    },
    SET_CARDS_VIDEOS(state, value) {
      state.card_videos = value;
    },
    SET_ALERTAHOME(state, value) {
      state.alerta_home = value;
    },
    SET_PREGUNTAS_FRECUENTES(state, value) {
      state.preguntas_frecuentes = value;
    },
    SET_ICON_CONTACTO(state, value) {
      state.icon_contacto = value;
    },
    SET_ULTIMAS_NOTICIAS(state, value) {
      state.ultimas_noticias = value;
    },
    SET_ULTIMAS_NOTICIAS_DROP(state, value) {
      state.ultimas_noticias_drop = value;
    },
    SET_GALERIA_HOME(state, value) {
      state.galeria_home = value;
    },
    SET_CARDS_COMITE(state, value) {
      state.card_comite = value;
    },
    SET_RESULTADOS(state, value) {
      state.resultados = value;
    },
    SET_IMGTOPBAR(state, value) {
      state.imgTopbar = value;
    },
    SET_CARRUSELHOME(state, value) {
      state.carruselhome = value;
    },
  },
  actions: {
    getAlertaHome({commit},) {
      return new Promise((resolve, reject) => {
        get(`contenidos/alerta-home`)
          .then((response) => {
            commit("SET_ALERTAHOME", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getCarruselHome({commit}) {
      return new Promise((resolve) => {
        get(`contenidos/carrusel-home`)
          .then((res) => {
            commit("SET_CARRUSELHOME", res.data);
            resolve();
          })
          .catch((err) => {
            console.log(err)
          });
      });
    },
    getImgTopbar({commit}) {
      return new Promise((resolve) => {
        get(`contenidos/topbar`)
          .then((res) => {
            commit("SET_IMGTOPBAR", res.data);
            resolve();
          })
          .catch((err) => {
            console.log(err)
          });
      });
    },
    getFeatures({commit}) {
      return new Promise((resolve) => {
        get(`contenidos/features`)
          .then((res) => {
            commit("SET_FEATURES", res.data);
            resolve();
          })
          .catch((err) => {
            console.log(err)
          });
      });
    },
    getVideo({commit}, name) {
      return new Promise((resolve) => {
        get(`videos/${name}`)
          .then((res) => {
            commit("SET_VIDEO", res.data);
            resolve();
          })
          .catch((err) => {
            console.log(err)
          });
      });
    },
    getVideos({commit}) {
      return new Promise((resolve) => {
        get(`videos`)
          .then((res) => {
            commit("SET_VIDEOSHOME", res.data.data);
            resolve();
          })
          .catch((err) => {
            console.log(err)
          });
      });
    },
    getCardVideos({commit}) {
      return new Promise((resolve) => {
        get(`contenidos/card-videos`)
          .then((res) => {
            commit("SET_CARDS_VIDEOS", res.data);
            resolve();
          })
          .catch((err) => {
            console.log(err)
          });
      });
    },
    getResultados({commit}) {
      return new Promise((resolve) => {
        get(`contenidos/tablas/resultados`)
          .then((res) => {
            commit("SET_RESULTADOS", res.data);
            resolve();
          })
          .catch((err) => {
            console.log(err)
          });
      });
    },
    getPreguntasFrecuentes({commit}) {
      return new Promise((resolve) => {
        get(`contenidos/preguntas-frecuentes`)
          .then((res) => {
            commit("SET_PREGUNTAS_FRECUENTES", res.data);
            resolve();
          })
          .catch((err) => {
            console.log(err)
          });
      });
    },
    getIconContacto({commit}) {
      return new Promise((resolve) => {
        get(`contenidos/iconos-contacto`)
          .then((res) => {
            commit("SET_ICON_CONTACTO", res.data);
            resolve();
          })
          .catch((err) => {
            console.log(err)
          });
      });
    },
    getUltimasNoticias({commit}) {
      return new Promise((resolve) => {
        get(`contenidos/ultimas-noticias`)
          .then((res) => {
            commit("SET_ULTIMAS_NOTICIAS", res.data);
            resolve();
          })
          .catch((err) => {
            console.log(err)
          });
      });
    },
    getUltimasNoticiasDrop({commit}) {
      return new Promise((resolve) => {
        get(`sistema/noticias/all`)
          .then((res) => {
            commit("SET_ULTIMAS_NOTICIAS_DROP", res.data);
            resolve();
          })
          .catch((err) => {
            console.log(err)
          });
      });
    },
    getGaleriaHome({commit}) {
      return new Promise((resolve) => {
        get(`contenidos/galeria-home`)
          .then((res) => {
            commit("SET_GALERIA_HOME", res.data[0]);
            resolve();
          })
          .catch((err) => {
            console.log(err)
          });
      });
    },
    getCardComite({commit}) {
      return new Promise((resolve) => {
        get(`contenidos/card-comite`)
          .then((res) => {
            commit("SET_CARDS_COMITE", res.data);
            resolve();
          })
          .catch((err) => {
            console.log(err)
          });
      });
    },
  },

  getters: {
    features: (state) => state.features,
    video: (state) => state.video,
    videos: (state) => state.videos,
    card_videos: (state) => state.card_videos,
    preguntas_frecuentes: (state) => state.preguntas_frecuentes,
    icon_contacto: (state) => state.icon_contacto,
    ultimas_noticias: (state) => state.ultimas_noticias,
    ultimas_noticias_drop: (state) => state.ultimas_noticias_drop,
    galeria_home: (state) => state.galeria_home,
    card_comite: (state) => state.card_comite,
    resultados: (state) => state.resultados,
    imgTopbar: (state) => state.imgTopbar,
    carruselhome: (state) => state.carruselhome,
    alerta_home: (state) => state.alerta_home,
  },
};
