/*eslint-disable*/
import {post, backend_url} from "@/services/ApiReq";

export default {
  state: {
    revista_images: [],
    revistas: [],
  },
  mutations: {
    SET_IMAGES(state, value) {
      let images = [];
      for (let image of value.files) {
        images.push(`${backend_url}/files/pdf/revistas/${value.name}/${image}`);
      }
      state.revista_images = images;
    },
    SET_REVISTAS(state, value) {
      state.revistas = value;
    },
  },
  actions: {
    getImages({commit}, value) {
      return new Promise((resolve) => {
        post("revistas/paginas", value).then((response) => {
          const params = {
            ...response.data,
            name: value.name.split(".")[0]
          }
          commit("SET_IMAGES", params);
          resolve();
        });
      });
    },
    getRevistas({commit}, value) {
      return new Promise((resolve) => {
        post("revistas", value).then((response) => {
          commit("SET_REVISTAS", response.data.revistas);
          resolve();
        });
      });
    },
  },
  getters: {
    revista_images: (state) => state.revista_images,
    revistas: (state) => state.revistas
  },
};
