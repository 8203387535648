<script setup>
import {ref, computed, onMounted} from 'vue'
import MenuSubCategory from './MenuSubCategory.vue'
const props = defineProps({
  category: {
    type: Object,
    required: true
  },
})

const hover = ref(false)
const active = ref(false)
const subs = computed(() => {
  const mappedContenidos = props.category.contenidos.map(cont => {
    const contenido = {
      ...cont,
      esContenido: true
    }
    if(cont.contenido_bonus){
      const label = cont.contenido_bonus.find(f => f.label === 'MENU-LABEL');
      if (label) {
        contenido.label = label.value;
      }
    }
    return contenido;
  })
  const mappedSubMenus = Object.values(props.category.submenu);
  const newArray = [...mappedSubMenus, ...mappedContenidos].sort((a, b) => {
    if(a.pos === b.pos){
      if (a.esContenido) {
        // Prioriza contenidos
        return -1
      } else {
        return 1
      }
    }
    return a.pos - b.pos
  })
  return newArray
})

const windowWith = computed(() => {
  return window.innerWidth
})

const handleMouse = (e) => {
  if (window.innerWidth < 900){
    if (!active.value){
      if (e.type === 'mouseenter') {
        hover.value = true
      } else if(e.type === 'mouseleave') {
        hover.value = false
      }
    }
    if (e.type === 'click'){
      active.value = !active.value
    }
  }
  else{
    if (e.type === 'mouseenter') {
      active.value = true
    } else if(e.type === 'mouseleave') {
      active.value = false
    }
  }
}

onMounted(() => {
})

</script>

<template>
   <li @mouseenter="handleMouse" @mouseleave="handleMouse">
          <div class="main-li" :class="`${!category.esSubCategoria && 'main-menu'} ${(hover || active) && 'isActive'}`">
            <a class="main-category-name" :class="(hover || active) && 'category-name'" :href="`${(subs.length) ? 'javascript:void(0)' : category.url}`">{{category.nombre}} <i v-if="subs.length && windowWith > 683" class="fa fa-angle-down"></i></a>
            <button @click="handleMouse" class="expand-button" v-if="(category.contenidos.length || Object.keys(category.submenu).length)"><i v-if="subs.length && windowWith < 683" class="fa fa-angle-down"></i></button>
          </div>
          <ul v-if="subs.length" id="HeaderNavFondo" class="menu-dropdown" :class="`${category.esSubCategoria && 'sub-menu'} ${(active) ? 'menu-open' : 'menu-close'}`">
            <MenuSubCategory v-for="(sub, index) in subs" :key="index" :sub="sub" />
          </ul>
    </li>
</template>

<style lang="scss" scoped>

  .icon{
    display: none;
  }
  .icon-on{
    display: inline-block;
  }
  .expand-button{
    display: none;
    background-color: transparent;
    color: black;
    font-size: 1.5rem;
    border: none;
    padding:0;
    margin:0;
    height: 100%;
    width: 50px;
    position: absolute;
    right: 10px;
    top:0px;
    @media (max-width: 900px) {
      display: inline-block;
    }
  }
  .main-li{
    display: flex;
    position: relative;
    margin: 0;
    justify-content: space-between;
    align-items: center;
     &.isActive{
        &:hover{
          & > .category-name{
          color: #fff;
          }
          & .expand-button{
            color: #fff;
          }
        cursor: pointer;
        background: #65c178 none repeat scroll 0 0;
        }
      }
    & a{
      color: #666666;
      font-size: 14px;
      font-weight: 600;
      text-decoration: none;
    }
    &.main-menu{
      padding: 12px;
      & > a.main-category-name{
        font-size: 16px;
        font-weight: 700;
        color: #333333;
      }
    }
    @media (max-width: 900px) {
      min-height: 30px;
      &.isActive{
        &:hover{
          & > .category-name{
          color: #fff;
          }
        cursor: pointer;
        background: #65c178 none repeat scroll 0 0;
        }
      }
      &.main-menu{
        padding: 12px 20px;
        & a{
          width: 100%;
          cursor: pointer;
        }
      }
    }
  }
  .menu-dropdown{
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    display:none;
    flex-direction: column;
    z-index: 99;
    min-width: 180px;
    white-space: nowrap;
    background: #fff;
    border: 1px solid #f0f0f0;
    &.sub-menu{
    left: 100%;
    margin-top: -41.5px;
    }
    & li{
      text-align: start;
      a{
        font-weight: 400;
        padding: 10px 25px 10px 22px;
        font-size: 1.1em;
        -webkit-transition: padding 0.2s linear;
        transition: padding 0.2s linear;
      }
      @media (min-width: 900px) {
        &:hover{
        & .category-name{
          color: #fff;
          padding: 10px 25px 10px 30px;
        }
        cursor: pointer;
        background: #65c178 none repeat scroll 0 0;
        }
      } 
    }

    @media (max-width: 900px) {
      position: relative;
      display: none;
      width: 100%;
      &.menu-open{
        display: flex;
      }
      &.sub-menu{
        position: relative;
        display:none;
        width: 100%;
        left: 0%;
        margin-top: 0px;
        &.menu-open{
          display: flex;          
        }
      }
      & li{
      text-align: start;
      // padding: 10px 30px;
        a {
        font-weight: 400;
        font-size: 12px;
        -webkit-transition: padding 0.2s linear;
        transition: padding 0.2s linear;
        padding: 10px 25px 10px 22px;
        }
        &:hover{
        & .category-name{
          padding: 10px 25px 10px 30px;
        }
        }
      }
    }
  }

  .menu-open{
    animation: growDown 150ms ease-in-out forwards;
    transform-origin: top center;
    display:flex;
  }

  .menu-close{
    animation: growUp 150ms ease-in-out forwards;
    transform-origin: top center;
  }

  @keyframes growDown {
    0% {
        transform: scaleY(0)
    }
    80% {
        transform: scaleY(0.8)
    }
    100% {
        transform: scaleY(1)
    }
  }
  @keyframes growUp {
    0% {
        transform: scaleY(1)
    }
    80% {
        transform: scaleY(0.2)
    }
    100% {
        transform: scaleY(0)
    }
  }

</style>