import { get } from "@/services/ApiReq";

/* eslint-disable */

export default {
  state: {
    estructura: {
      torneos: null,
      years: null,
      calendarios: null,
      resultados: null
    },
  },
  mutations: {
    SET_ESTRUCTURA(state, value) {

      state.estructura.resultados = value.data.data;
      state.estructura.resultados.total = value.data.total;


    },
    SET_ESTRUCTURA_FILTERS(state, value) {
      state.estructura.torneos = value.torneos;
      state.estructura.years = value.years;
      state.estructura.calendarios = value.calendarios;
      /*
        state.exposicion.torneos = value.torneos ? value.torneos : state.exposicion.torneos;
        state.exposicion.years = value.years ? value.years : state.exposicion.years;
        state.exposicion.calendarios = value.calendarios ? value.calendarios : state.exposicion.calendarios;*/
    },
    CLEAR_ESTRUCTURA(state, payload) {
      const { torneos, years, calendarios, resultados } = payload;
      if (torneos) state.expos.torneos = null;
      if (years) state.estructura.years = null;
      if (calendarios) state.estructura.calendarios = null;
      if (resultados) state.estructura.resultados = null;
    },
  },
  actions: {
    getEstructura({ commit }, { url, params }) {
      return new Promise((resolve, reject) => {

        get(`sistema/exposicion/${url}`, { params })
          .then((res) => {

            commit("SET_ESTRUCTURA", res);
            resolve();
          })
          .catch((err) => {
            reject(err);
            console.log(err)
          });
      });
    },
    getEstructuraFilters({ commit }, { params }) {
      return new Promise((resolve, reject) => {
        params.tipo = 'estructura';
        get(`sistema/filters/`, { params })
          .then((res) => {
            commit("SET_ESTRUCTURA_FILTERS", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
            console.log(err)
          });
      });
    },
  },

  getters: {
    estructura: (state) => state.estructura,
  },
};


