<script>
/* eslint-disable */
// import PreguntasFrecuentes from "./PreguntasFrecuentes.vue";
// import 'vue3-carousel/dist/carousel.css';
// import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import UltimasNoticias from "./UltimasNoticias.vue";
import AcordeonFechasSlot from "../../../../components/Acordeon/AcordeonFechasSlot.vue";
import AcordeonFechasDobleSlot from "../../../../components/Acordeon/AcordeonFechasDobleSlot.vue";
import {ad_url} from "../../../../services/ApiReq.js";

import {mapGetters} from "vuex";

export default {
  beforeMount() {
    this.ad = ad_url;
  },
  async mounted() {
    // await this.$store.dispatch("getPreguntasFrecuentes");
    await this.$store.dispatch("getUltimasNoticias");
    await this.$store.dispatch("getUltimasNoticiasDrop");

    let size = 3;
    for (let i = 0; i < this.UltimaNoticia.length; i += size) {
      this.notices.push(this.UltimaNoticia.slice(i, i + size));
    }
  },
  data: () => {
    return {
      // screen_width: window.innerWidth,
      mesesKey: {
        1: "Enero",
        2: "Febrero",
        3: "Marzo",
        4: "Abril",
        5: "Mayo",
        6: "Junio",
        7: "Julio",
        8: "Agosto",
        9: "Septiembre",
        10: "Octubre",
        11: "Noviembre",
        12: "Diciembtre",
      },
      notices: [],
      ad: null,
    };
  },
  components: {
    // PreguntasFrecuentes,
    UltimasNoticias,
    AcordeonFechasSlot,
    AcordeonFechasDobleSlot,
    // Carousel,
    // Slide,
    // Pagination,
    // Navigation,
  },
  computed: {
    ...mapGetters(["isMobile"]),
    Years() {
      let y = [];
      for (let i = 2030; i >= 2020; i--) {
        y.push(i);
      }
      return y;
    },
    monthActual() {
      let fecha = new Date();
      return fecha.getMonth() + 1;
    },
    yearActual() {
      let fecha = new Date();
      return fecha.getFullYear();
    },
    // PreguntasFrecuentes() {
    //   return this.$store.getters.preguntas_frecuentes;
    // },
    // UltimaNoticiaCarrusel() {
    //   return this.$store.getters.ultimas_noticias;
    // },
    UltimaNoticia() {
      if (this.$store.getters.ultimas_noticias.length < 6) {
        return this.$store.getters.ultimas_noticias;
      }
      let noticias = this.$store.getters.ultimas_noticias.slice(
          this.$store.getters.ultimas_noticias.length - 6
      );
      noticias = noticias.sort((a, b) => {
        if (a["desde"] < b["desde"]) {
          return -1;
        }
        if (a["desde"] > b["desde"]) {
          return 1;
        }
        return 0;
      });
      return noticias;
    },
    // UltimaNoticiaCarrusel() {
    //   return this.$store.getters.ultimas_noticias.slice(0,-3);
    // },
    // UltimaNoticiaAcordeon() {
    //   return this.$store.getters.ultimas_noticias;
    // },
    fechasReduce() {
      let reduced = [
        this.$store.getters.ultimas_noticias_drop.reduce((acc, item) => {
          let year = new Date(item.desde).getFullYear();
          let month = new Date(item.desde).getMonth() + 1;
          let day = new Date(item.desde).getDay();

          if (!acc[year]) {
            acc[year] = {};
          }
          if (!acc[year][month]) {
            acc[year][month] = [];
          }
          acc[year][month].push(item);

          return acc;
        }, {}),
      ];

      let res = reduced[0];

      Object.keys(res).map((year) => {
        Object.keys(res[year]).map((month) => {
          let sortedMonth = res[year][month].sort((a, b) => {
            if (a["desde"] < b["desde"]) {
              return 1;
            }
            if (a["desde"] > b["desde"]) {
              return -1;
            }
            return 0;
          });
          res[year][month] = sortedMonth;
        });
      });

      return reduced;
    },
  },
};
</script>

<template>
  <section id="blog">
    <div class="container pt-60 pb-60">
      <div class="col-md-9">
        <h3 class="mt-0 line-height-1 pb-20 text-center">
          Últimas <span class="text-theme-colored"> Noticias</span>
        </h3>

        <div class="row">
          <!-- <div id="grid3" class="gallery-isotope grid-4 gutter clearfix">
            <div id="Acordeon-grid">
              <UltimasNoticias
                v-for="card in UltimaNoticia"
                :key="card.id"
                :item="card"
              />
            </div>
          </div> -->

          <div class="notices-container">
            <div
                v-for="(set, index) in notices"
                :key="index"
                class="set-container"
            >
              <div
                  class="notices-content"
                  :class="isMobile ? 'mobile-notices-content' : ''"
              >
                <div
                    v-for="(notice, subindex) in notices[index]"
                    :key="subindex"
                    class="notice-container"
                    :class="isMobile ? 'mobile-notice-container' : ''"
                >
                  <UltimasNoticias :item="notice" class="notice"/>
                </div>
              </div>
              <div class="publicidad-horizontal">
                <iframe
                    :src="`${ad}/publicidad?type=horizontal`"
                    width="770"
                    height="150"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="row otrasNoticias">
          <template v-if="fechasReduce">
            <h3 class="mt-0 line-height-1 pb-20 text-center">
              <span class="text-theme-colored">Otras </span>Noticias
            </h3>
            <template v-for="(fechaReduce, index) in fechasReduce" :key="index">
              <template v-for="Year in Years" :key="Year">
                <template v-if="fechaReduce[Year]">
                  <AcordeonFechasDobleSlot
                      :yearXHref="`title-${Year}`"
                      :autoExpand="yearActual == Year"
                  >
                    <template #title-doble-slot>
                      <i class="fa fa-plus"></i
                      ><em
                        style="padding-left: 1.5em"
                        class="text-theme-colored"
                    >
                      Año: </em
                    ><em>{{ `${Year}` }}</em>
                    </template>

                    <template #inner-acordeon-doble-slot>
                      <div
                          :id="`calendario-Arcordeon-${Year}`"
                          class="panel-group accordion"
                      >
                        <AcordeonFechasSlot
                            v-for="(itemReduce, mes) in fechaReduce[Year]"
                            :key="mes"
                            :mesName="mesesKey[mes]"
                            :yearXHref="Year.toString()"
                        >
                          <!-- :autoExpand="monthActual == mes && yearActual == year" -->
                          <template #innerAcordeon>
                            <UltimasNoticias
                                v-for="cardReduce in itemReduce"
                                :key="cardReduce.id"
                                :item="cardReduce"
                            />
                          </template>
                        </AcordeonFechasSlot>
                      </div>
                    </template>
                  </AcordeonFechasDobleSlot>
                </template>
              </template>
            </template>
          </template>
        </div>

        <div class="publicidad">
          <iframe
              :src="`${ad}/publicidad?type=button`"
              width="255"
              height="255"
          ></iframe>
        </div>

        <div class="publicidad">
          <iframe
              :src="`${ad}/publicidad?type=button&fixed=true&tag=home_01`"
              width="255"
              height="255"
          ></iframe>
        </div>

        <div class="publicidad">
          <iframe
              :src="`${ad}/publicidad?type=skycrapper`"
              width="255"
              height="700"
          ></iframe>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.slide-heigth {
  min-height: 100%;
}

.otrasNoticias {
  height: 300px;
  overflow-y: scroll;
}

.notices-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.notices-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notice-container {
  width: 30%;
}

.notice {
  height: 345px;
}

/* comentar esto para mostrar mas tarjetas */
@media (max-width: 1980px) and (min-width: 1401px) {
  .acomodar-merda {
    position: relative;
    left: 25%;
    width: 50%;
  }
}

@media (max-width: 1400px) and (min-width: 1001px) {
  .acomodar-merda {
    position: relative;
    left: 20%;
    width: 65%;
  }
}

@media (max-width: 1000px) {
  .acomodar-merda {
    position: static;
    left: 0;
    width: 100%;
  }
}

.publicidad {
  margin-top: 2em;

  display: flex;
  align-content: center;
  justify-content: center;

}

.publicidad-horizontal {
  width: 770px;
  margin: 2em auto;
  height: fit-content;

  display: flex;
  align-items: center;
  justify-content: center;

}

.mobile-notices-content {
  flex-direction: column;
}

.mobile-notice-container {
  width: 100%;
}

.set-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
